import React from 'react';
import {Routes, Route, NavLink, Navigate} from 'react-router-dom';
import {useAppContext} from "../../../context/Context";
import TitleAndSubTitle from '../settings/TitleAndSubTitle';
import { twMerge } from 'tailwind-merge';
import Affichage from './Affichage/Affichage';
import "./Technical.css";

const Technical: React.FC = () => {
    const [{ isAdminVirteem }, _] = useAppContext();

    return (
        <main className="main-technical">
            {isAdminVirteem &&
            <><TitleAndSubTitle h1="Gestion Technique" h2="Gérez les paramètres de votre Virteem Companion" /><div className="technical-container">
                    {/* Navigation menu with links to Home, Change Password, Reset Password, and Profile pages */}
                    <nav className="technical-nav">
                        <ul>
                            <li>
                                <NavLink to="affichage"
                                    className={twMerge(`${({ isActive }) => (isActive ? 'active' : '')} w-full`)}
                                    end>Affichage</NavLink> {/* Link to Home page */}
                            </li>
                        </ul>
                    </nav>

                    {/* Define routes for the technical section */}
                    <div className="technical-content">
                        <Routes>
                            <Route path="affichage" element={<Affichage/>}/> 
                            <Route path="/" element={<Navigate to="/administration/admin-technical/affichage" />} /> {/* Default route */}
                        </Routes>
                    </div>
                </div></>
            }
        </main>
    );
}
export default Technical;