import React, { useState } from 'react'
import { useAppContext } from '../../context/Context'
import api from '../../utils/api'
import { ACTION, BOTTOM_BAR_SEVERITY } from '../../context/actionTypes';
import { setBottomBarError } from '../../utils/Functions';

export default function useFeedbacks({ question, response, companyId }) {
    const [_, dispatch] = useAppContext()
    const [feedback, setFeedback] = useState('')
    const [face, setFace] = useState(null)

    const handleSubmit = async () => {
        console.log("Sending feedback from", question, "with response", response, "and feedback", feedback);
        try {
            // Send feedback to backend
            await api.post(`/feedback/${companyId}`, { new_feedback: { content: feedback, data: { question, response, face }, created_at: new Date().toISOString() } });
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })
            dispatch({
                type: ACTION.SETBOTTOMBAR,
                payload: {
                    open: true,
                    severity: BOTTOM_BAR_SEVERITY.SUCCESS,
                    message: "Avis Envoyé !",
                },
            })
        }
        catch (error) {
            setBottomBarError(dispatch, error)
        }
    }

    return [{
        feedback,
        face
    }, {
        setFeedback,
        handleSubmit,
        dispatch,
        setFace
    }]
}
