import React from 'react';
import GenericModal from '../../../utils/GenericModal';
import { ACTION } from '../../../context/actionTypes';
import { InputComponent,SwitchComponent } from '../../../utils/FormComponents';
import useCreateOrUpdateCompany from '../../../hooks/companies/useCreateOrUdpateCompany';

const CreateOrUpdateCompanyModal = (props) => {
    const [{
        company,
        errors,
    }, {
        dispatch,
        setCompany,
        handleInputChange,
        handleSubmit
    }] = useCreateOrUpdateCompany(props);

    return (
        <GenericModal
            className="w-3/4 lg:w-2/5 xl:w-1/2 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={false}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Modifier / Ajouter une Entreprise</div>
            <form onSubmit={handleSubmit} noValidate>
                <div className='grid grid-cols-2 gap-4 p-8'>
                    <InputComponent
                        label="Nom de l'entreprise :"
                        name="name"
                        value={company.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                    <InputComponent
                        label="Email :"
                        name="email"
                        value={company.email}
                        onChange={handleInputChange}
                        error={errors.email}
                    />
                    <InputComponent
                        label="Secteur :"
                        name="sector"
                        value={company.sector}
                        onChange={handleInputChange}
                        error={errors.sector}
                    />
                    <InputComponent
                        label="Zone Géographique :"
                        name="geographical_zone"
                        value={company.geographical_zone}
                        onChange={handleInputChange}
                        error={errors.geographical_zone}
                    />
                    <SwitchComponent
                            label="Etat du compte :"
                            name="status"
                            checked={company.status}
                            onChange={handleInputChange}
                            checkedLabel="Activé"
                            uncheckedLabel="Désactivé"
                            className='col-span-2 xl:col-span-1'
                        />
                </div>
                <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                    <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className='rounded border bg-white text-black text-sm font-light py-1 px-2'>
                        Annuler
                    </button>
                    <button type='submit' className='rounded bg-gray-600 hover:bg-gray-700 hover:shadow text-white text-sm py-1 px-2 transform duration-300'>
                        Valider
                    </button>
                </div>
            </form>
        </GenericModal>
    );
};

export default CreateOrUpdateCompanyModal;
