import { useEffect, useState } from "react"
import api from "../../utils/api"
import { useAppContext } from "../../context/Context"
import { ACTION, GENERIC_MODAL_ACTIONS } from "../../context/actionTypes"

export default function usePlans() {
    const [_, dispatch] = useAppContext()
    const [plans, setPlans] = useState([])

    useEffect(() => {
        loadPlans()
    }, [])


    const loadPlans = async () => {
        const response = await api.get('/plan/all')
        setPlans(response.data)
    }

    const createPlan = async () => {
        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                props: { reload: loadPlans },
                action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_PLAN
            }
        })
    }
    return [{
        plans
    }, {
        loadPlans,
        createPlan
    }]
}
