import React from 'react'

export default function Clients() {
  return (
    <div className=' flex flex-col'>
      <div className='h-32 <-full bg-green-300 flex-shrink-0'></div>
      <div className=' bg-blue-300 w-full flex-grow'>
        <div>bonjour</div>
      </div>
    </div>
  )
}
