import React from "react";
import "./InfoMessageNote.css";

interface InfoMessageNoteProps {
  display: any;
}

const InfoMessageNote: React.FC<InfoMessageNoteProps> = ({ display }) => {
     return (
         <div className="flex flex-col info-message">{display}</div>
  );
}
export default InfoMessageNote;