import React, {useState} from 'react';
import SubmitButton from "./SubmitButton";
import './progressBar.css';
import './ChangePassword.css';
import ErrorMessageNote from "../messageNote/ErrorMessageNote";
import SuccessMessageNote from "../messageNote/SuccessMessageNote";
import TitleAndSubTitle from "../TitleAndSubTitle";
import GenericModal from "../../../../utils/GenericModal";
import ProgressBarMessageNote from "../messageNote/ProgressBarMessageNote";
import PwdCriteriaChecker from "./PwdCriteriaChecker";
import ToggleCheckbox from "../ToggleCheckBox";
import useAuthentication from "../../../../hooks/useAuthentication";
import {Step} from "./Step";
import {useNavigate} from "react-router-dom";
import BlueLink from "./BlueLink";
import api from "../../../../utils/api";
import axios from "axios";

interface ResetPasswordProps{
    finalStep? : Step
    title? : string
}

/** Main component for changing the password
 *
 * @constructor
 */
const ResetPassword: React.FC<ResetPasswordProps> = ({finalStep = Step.Logout, title ="Récupération"}) => {
    // State declarations for email, passwords, code, and messages
    const [step, setStep] = useState<Step>(Step.EnterEmail); // Current step
    const [error, setError] = useState(''); // Error message
    const [success, setSuccess] = useState(''); // Success message
    const [email, setEmail] = useState(''); // User's email
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
    const [enteredCode, setEnteredCode] = useState(''); // Code entered by user
    const [newPassword, setNewPassword] = useState(''); // New password
    const [confirmPassword, setConfirmPassword] = useState(''); // Confirm new password
    const [showPassword, setShowPassword] = useState(false);
    const [_, { logOut }] = useAuthentication();
    const navigate = useNavigate();

    /** Toggle modal visibility */
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        setStep(Step.EnterEmail);
    };

    /** Submission email management
     * Prevents form reloading by default.
     * Sends email to API to get code.
     *
     * @param event
     */
    const handleSendEmail = async (event: React.FormEvent) => {
        event.preventDefault();
        setError('');
        setSuccess('');

        const options = {
             headers: {
                    'Content-Type': 'application/json'
                }
        };
        const body = {
            email
        };

        try {
            const response = await api.post('/user/password_reset/send_validation_code', body,
            options);
            if(response.status === 200){
                const successMessage = 'Un code temporaire vous a été envoyé à l\'adresse email vous avez renseignée.';
                setSuccess(successMessage);
                setStep(Step.EnterCode);
            }
        } catch (error) {
            const errorMessage = "Une erreur s'est produite lors de l'envoie du code. Veuillez essayer ultérieurement"

            if (axios.isAxiosError(error)) {
                  console.debug(handleChangePassword.name, error.response);
                 setError(error.response?.data?.detail || errorMessage);
             }
             else {
                 console.debug(handleChangePassword.name, error);
                 setError(errorMessage);
             }
        }
    };

    /** Submission password management
     * Prevents form reloading by default.
     * Checks if new passwords match.
     * Checks the strength of the new password.
     * If the conditions are met, an alert indicates that the password has been changed.
     *
     * @param event
     */
    const handleChangePassword = async (event: React.FormEvent) => {
        event.preventDefault();
        setError('');
        setSuccess('');

        if (newPassword !== confirmPassword) {
            setError('Les nouveaux mots de passe ne correspondent pas.');
            return;
        }
        const options = {
             headers: {
                    'Content-Type': 'application/json'
                }
        };
        const body = {
             code: enteredCode,
                    password_update: {
                        password: newPassword,
                        confirm_password: confirmPassword
                    }
        };

        try {
            const response = await api.post('/user/password_reset/submit', body,
            options);
            const successMessage = 'Le mot de passe a été modifié avec succès. Nous vous incitons à vous reconnecter sur la plateforme.';
            if(response.status === 200){
                setSuccess(successMessage);
                setEmail('');
                setNewPassword('');
                setConfirmPassword('');
                setStep(Step.Logout);
            }

        } catch (error) {
            const errorMessage = 'Erreur lors de la modification du mot de passe final.';
            if (axios.isAxiosError(error)) {
                  console.debug(handleChangePassword.name, error.response);
                 setError(error.response?.data?.detail || errorMessage);
             }
             else {
                 console.debug(handleChangePassword.name, error);
                 setError(errorMessage);
             }
            console.debug(handleChangePassword.name, Object.keys(error))
        }
    };

    /** Submission code management
     * Prevents form reloading by default.
     * Validates the code sent by email.
     *
     * @param event
     */
    const handleValidateCode = async (event: React.FormEvent) => {
        event.preventDefault();
        setError('');
        setSuccess('');

        const options = {
             headers: {
                    'Content-Type': 'application/json'
                }
        };
        const body = { email, code: enteredCode };

        try {
            const response = await api.post('/user/password_reset/validate_code', body,
            options);
            if(response.status === 200){
                setSuccess('Code valide');
                setStep(Step.ChangePassword);
            }
        } catch (error) {
            const errorMessage = 'Le code que vous avez fourni et invalide ou a expiré. Veuillez fermer la pop-up et essayer de nouveau.';
            if (axios.isAxiosError(error)) {
                console.debug(handleChangePassword.name, error.response);
                setError(error.response?.data?.detail || errorMessage);
            }
            else {
                console.debug(handleChangePassword.name, error);
                setError(errorMessage);
            }
        }
    };

    /** Updates the status of the new password and the password strength each time the input field is changed.
     *
     * @param event
     */
    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value;
        setNewPassword(password);
    };

     /** Handle logout and close modal */
    const handleLogoutAndCloseModal = async () => {
        setError("");
        setSuccess("");
        toggleModal();
         try{
            await logOut();
        }catch (error){
             console.error("Un problème est survenu lors de la déconnexion ", error);
         }

     };
    /** Handle close modal and navigate */
    const handleCloseModalAndNavigate = (root ='/administration') => {
        setError("");
        setSuccess("");
        toggleModal();
        navigate(root);
    };

    return (
        /** Return form :
         * Contains fields for email, current password, new password and new password confirmation.
         * Displays password strength in real time.
         * Displays errors if present.
         * Contains a submit button to change password.
         *
         */
        <main className="change-pwd">
            <TitleAndSubTitle h1={title ?? "Récupération"} h2="Vous avez oublié votre mot de passe et les informations de sécurité relatifs à votre compte" />
            <p>
                Nous pouvons vous aider à réinitialiser votre mot de passe et récupérer votre compte.
                Cliquez sur le bouton, entrez votre email et suivez les prochaines instructions.
            </p>
            <hr />
            <SubmitButton text={"Commencer"} onClick={toggleModal} />
            {isModalOpen && (
                <GenericModal toggleMe={toggleModal} showCloseButton={true} exitClick={false} blurBackGround={true} className="w-3/4 lg:w-2/5 xl:w-1/2 2xl:w-1/4 text-base text-black rounded-lg">
                    <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Récupération de votre compte
                    </div>
                    {step === Step.EnterEmail && (
                        <form onSubmit={handleSendEmail}>
                            <div className="flex flex-col gap-2">
                                <p>Un code de récupération temporaire sera envoyé à l'adresse email que vous fournirez
                                    ci-dessous.</p>
                                <label htmlFor="email">Email</label>
                                <br/>
                                <input
                                    type="email"
                                    id="email"
                                    className="input-text w-full"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required // Field is required
                                />
                            </div>
                            <SubmitButton text={"Suivant"}/>
                            <BlueLink text={"J'ai déjà un code"} onClick={() => setStep(Step.EnterCode)} />
                        </form>
                    )}
                    {step === Step.EnterCode && (
                        <form onSubmit={handleValidateCode}>
                            <div className="flex flex-col gap-2">
                                <p>Veuillez récupérer le code temporaire qui vous a été envoyé à l'adresse email
                                    : {email}</p>
                                <label htmlFor="code">Code temporaire</label>
                                <br/>
                                <input
                                    id="code"
                                    type="text"
                                    className="input-text w-full"
                                    value={enteredCode}
                                    onChange={(e) => setEnteredCode(e.target.value)}
                                    required // Field is required
                                />
                            </div>
                            <SubmitButton text={"Suivant"}/>
                            <BlueLink text={"Vous n'avez pas reçu de code ou votre code a expiré?"} onClick={() => setStep(Step.EnterEmail)} />
                        </form>
                    )}
                    {step === Step.ChangePassword && (
                        <form onSubmit={handleChangePassword}>
                            <div>
                                <label htmlFor="newPassword">Entrez le nouveau mot de passe</label>
                                <br/>
                                <input
                                    className="input-text w-full"
                                    id="newPassword"
                                    type={
                                        showPassword ? "text" : "password"
                                    }
                                    value={newPassword}
                                    onChange={handleNewPasswordChange}
                                    required // Field is required
                                />
                                {newPassword.length >= 1 && <ProgressBarMessageNote password={newPassword}/>}
                                <PwdCriteriaChecker password={newPassword}/>
                            </div>
                            <div>
                                <label htmlFor="confirmPassword">Confirmer votre nouveau mot de passe</label>
                                <br/>
                                <input
                                    className="input-text w-full"
                                    type={showPassword ? "text" : "password"}
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required // Field is required
                                />
                            </div>
                            <br/>
                            <ToggleCheckbox
                                id="check"
                                // label="Afficher le mot de passe 2"
                                checked={showPassword}
                                onChange={() => setShowPassword(prev => !prev)}
                            />
                            <SubmitButton text="Valider"/>
                        </form>
                    )}
                    {step === Step.Logout && (finalStep === Step.Logout) && (
                        <div className="flex flex-col px-4 py-3 gap-2">
                            <p>Nous vous invitons à vous déconnecter et à vous connecter de nouveau.</p>
                             <SubmitButton text={"Se déconnecter"} onClick={handleLogoutAndCloseModal} />
                        </div>

                    )}
                    {step === Step.Logout && (finalStep === Step.Login) && (
                        <div className="flex flex-col px-4 py-3 gap-2">
                            <p>Nous vous invitons à vous connecter.</p>
                            <SubmitButton text={"Se connecter"} onClick={() => handleCloseModalAndNavigate('/administration')} />
                        </div>
                    )}
                    <div className="px-4 py-3">
                        {error && <ErrorMessageNote display={error}/>}
                        {success && <SuccessMessageNote display={success}/>}
                    </div>
                </GenericModal>
            )}
        </main>
    );
}
export default ResetPassword;
