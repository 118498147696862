import React from 'react'
import { useAppContext } from '../../context/Context';
import api from '../../utils/api';
import { setBottomBarError } from '../../utils/Functions';
import { ACTION } from '../../context/actionTypes';

export default function useDeleteOption(props) {
    const [_, dispatch] = useAppContext();
    const { id, reload } = props;

    const deleteOption = async () => {
        try {
            await api.delete(`/option/${id}`);
            await reload();
            toggleMe();
        } catch (err) {
            console.error("Failed to option", err);
            setBottomBarError(dispatch, err);
        }
    };

    const toggleMe = () => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });

    return [{}, { deleteOption, toggleMe }];
}
