import { useEffect, useState } from 'react';
import api from '../../utils/api';
import { useAppContext } from '../../context/Context';
import { setBottomBarError } from '../../utils/Functions';
import { ACTION } from '../../context/actionTypes';

export default function useManageCompanyPlan({ id: company_id, plans, reload }) {
    const [_, dispatch] = useAppContext();
    const [originalPlanId, setOriginalPlanId] = useState(null)
    const [futurPlans, setFuturPlans] = useState(null)
    const [allPlans, setAllPlans] = useState(null)
    const [selectedPlanId, setSelectedPlanId] = useState(null)

    useEffect(() => {
        const init = async () => {
            try {
                const response = await api.get('/plan/all');
                setOriginalPlanId(findActivePlan(plans));
                setFuturPlans(findFuturePlans(plans));
                setAllPlans(response.data);

            } catch (error) {
                console.error("Failed to load plan data", error);
                setBottomBarError(dispatch, error)
            }
        };
        init();
    }, []);

    useEffect(() => {
        if (originalPlanId) {
            setSelectedPlanId(originalPlanId)
        }
    }, [originalPlanId])

    const findActivePlan = (plans) => {
        const today = new Date();
        const activePlan = plans.find(plan => {
            const startDate = new Date(plan.starts_at);
            const endDate = plan.ends_at ? new Date(plan.ends_at) : null;

            return today >= startDate && (endDate ? today <= endDate : true);
        });

        return activePlan ? activePlan.plan_id : null;
    };

    const findFuturePlans = (plans) => {
        const today = new Date();
        const futurePlans = plans.filter(plan => {
            const startDate = new Date(plan.starts_at);
            return startDate > today;
        });

        return futurePlans.length > 0 ? futurePlans : 'No future plans found.';
    };

    const handleSubmit = async () => {
        try {
            if (originalPlanId !== selectedPlanId) {
                await api.put(`/company/${company_id}/plan`, { new_plan: { plan_id: selectedPlanId, start_at: new Date().toISOString() } });
                await reload()
            }
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });
        } catch (error: any) {
            console.error("Failed to update plan", error);
            setBottomBarError(dispatch, error);
        }
    }

    return [{
        originalPlanId,
        selectedPlanId,
        futurPlans,
        allPlans,
    }, {
        setSelectedPlanId,
        dispatch,
        handleSubmit,
    }];
}
