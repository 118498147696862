import { Fragment } from 'react';
import GenericModal from '../../../utils/GenericModal';
import useReplyFeedback from '../../../hooks/feedbacks/useReplyFeedback';
import useAddKnowledgeBase from '../../../hooks/feedbacks/useAddKnowledgeBase';
import { ACTION } from '../../../context/actionTypes';
import { TextAreaComponent, InputComponent } from '../../../utils/FormComponents';
import { FaUserCircle } from 'react-icons/fa';
import { RiRobot2Line } from 'react-icons/ri';
import MarkDownFormater from '../../chat/MarkDownFormater';

export function getFaceIndex(face) {
    switch (face) {
        case 'good':
        case "\ud83d\ude42": // visage souriant légèrement encodé en js
            return 0;
        case 'medium':
        case "\ud83d\ude41": //visage légèrement renfrogné en js
            return 1;
        case 'bad':
        case "\ud83d\ude10": //visage neutre en js
            return 2;
        default:
            return 3;
    }
}

export function getFaceImage(index) {
    switch (index) {
        case 0:
            return <img className='w-6' src='/green.svg' alt="very satisfied" />;
        case 1:
            return <img className='w-6' src='/orange.svg' alt="satisfied" />;
        case 2:
            return <img className='w-6' src='/red.svg' alt="Unsatisfied" />;
        default:
            return <img className='w-6' src='/grey.svg' alt="Neutral" />;
    }
}

export default function ReplyFeedbackModal(props) {
    console.log("PROPS", props);
    const [{ response }, { setResponse, handleSubmit, dispatch,handleSubmitNoUser }] = useReplyFeedback(props);
    const { title, setTitle, content, setContent, handleSubmit: handleKnowledgeSubmit } = useAddKnowledgeBase();

    // Condition pour désactiver le bouton
    const isKnowledgeButtonDisabled = !title.trim() || !content.trim();
    
    return (
      <GenericModal
            className="w-full max-w-5xl max-h-screen overflow-hidden text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={false}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Répondre à un feedback</div>

            {/* Section Feedback client */}
            <div className='p-4 bg-virteem-bgBlue text-white overflow-y-auto max-h-[30vh] rounded-t-lg'>
                <div>Contexte :</div>
                <div className='flex gap-2 mt-2'>
                    <div className='pt-3'>
                        <FaUserCircle className='h-5 w-5' />
                    </div>
                    <div className={`relative gap-2 text-base flex-grow`}>
                        <div className={`bg-gray-400 p-2 pr-8 rounded-lg text-white inline-block w-full`}>
                            <MarkDownFormater>
                                {props.data?.question?.message}
                            </MarkDownFormater>
                        </div>
                    </div>
                </div>

                <div className='flex gap-2 mt-4'>
                    <div className='pt-3'>
                        <RiRobot2Line className='h-5 w-5' />
                    </div>
                    <div className={`relative flex-grow gap-2 text-base`}>
                        <div className={`bg-gray-500 p-2 pr-8 rounded-lg text-white w-full`}>
                            <MarkDownFormater>
                                {props.data?.response?.message}
                            </MarkDownFormater>
                        </div>
                    </div>
                </div>
                <div className='mt-4'>
                    <div>Feedback client :</div>
                    <div className='text-4xl'>{getFaceImage(getFaceIndex(props.data?.face))}</div>
                    <div className={`text-white italic`}>
                        "{props.content}"
                    </div>
                </div>
            </div>

      {/* Section pour répondre au feedback */}
      {props.user_id && !props.handled_at ? (
            <div className='p-4 border-t bg-white overflow-y-auto max-h-[35vh]'>
                <div className='flex flex-col w-full'>
                    <TextAreaComponent
                        className='w-full'
                        label='Réponse :'
                        name='feedback'
                        value={response}
                        onChange={(e) => setResponse(e.target.value)}
                        rows={4}
                    />
                    <div className='flex justify-end mt-2'>
                        <button onClick={handleSubmit} type='button' className='rounded bg-gray-500 hover:bg-gray-600 text-white text-sm font-medium py-2 px-4'>
                            Envoyer la réponse
                        </button>
            </div>
        </div>
    </div>
      ) : (
        ''
      )}

            {/* Section pour ajouter du contenu à la base de connaissances */}
            <div className='p-4 border-t bg-white overflow-y-auto max-h-[35vh]'>
                <div className='flex flex-col w-full'>
                    <h3 className="font-semibold text-sm">Ajouter à la base de connaissances :</h3>
                    <InputComponent
                        className='w-full'
                        label='Titre du document :'
                        name='docTitle'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextAreaComponent
                        className='w-full'
                        label='Contenu du document :'
                        name='docContent'
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        rows={4}
                    />
                    <div className='flex justify-end mt-2'>
                        <button 
                            onClick={handleKnowledgeSubmit} 
                            type='button' 
                            className={`rounded text-white text-sm font-medium py-2 px-4 ${isKnowledgeButtonDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-gray-500 hover:bg-gray-600'}`}
                            disabled={isKnowledgeButtonDisabled}
                        >
                            Ajouter à la base de connaissances
                        </button>
            <button
              onClick={handleSubmitNoUser}
              type='button'
              className='rounded text-white text-sm font-medium py-2 px-4 bg-gray-500 hover:bg-gray-600'
            >
              Marquer comme traitée
            </button>
            </div>
                </div>
            </div>
            {/* Section pour les boutons d'action */}
            <div className='flex justify-end gap-4 p-4 border-t bg-gray-50'>
                <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className='rounded border bg-white text-black text-sm font-light py-2 px-4'>
                    Annuler
                </button>
            </div>
        </GenericModal>
    );
}
