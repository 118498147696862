import { useAppContext } from "../../context/Context";
import { ACTION } from "../../context/actionTypes";
import { setBottomBarError } from "../../utils/Functions";
import api from "../../utils/api";

export default function useDeleteDocument(props) {
    const [_, dispatch] = useAppContext();
    const { ids, reload } = props;

    const deleteDocuments = async () => {
        try {
            for (const id of ids) {
                await api.delete(`/document/${id}`);
            }
            await reload();
            toggleMe();
        } catch (err) {
            console.error("Failed to delete documents", err);
            setBottomBarError(dispatch, err);
        }
    };

    const toggleMe = () => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });

    return [{}, { deleteDocuments, toggleMe }];
}
