import GenericModal from '../../../utils/GenericModal';
import { ACTION } from '../../../context/actionTypes';
import { InputComponent } from '../../../utils/FormComponents';
import useCreateOrUpdateSalesRepModal from '../../../hooks/salesrep/useCreateOrUpdateSalesRepModal';

export default function CreateOrUpdateSalesRepModal(props) {
    const [{ salesRep, errors }, { dispatch, handleSubmit, handleInputChange }] = useCreateOrUpdateSalesRepModal(props);

    const { first_name, last_name, email } = salesRep;

    return (
        <GenericModal
            className="w-3/4 lg:w-2/5 xl:w-1/2 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={false}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Créer / Modifier un commercial référent</div>
            <form onSubmit={handleSubmit} noValidate className=''>
                {/* <div className='mb-4'>
                    <label htmlFor="profile_picture" className="block text-gray-700 text-sm font-bold mb-2">
                        Profile Picture:
                    </label>
                    <input
                        type="file"
                        id="profile_picture"
                        name="profile_picture"
                        accept="image/*"
                        onChange={(e) => handleInputChange(e, true)} // Notice the second parameter to indicate a file input change
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div> */}
                <div className='p-8'>
                    <InputComponent
                        label="Prénom:"
                        name="first_name"
                        value={first_name}
                        onChange={handleInputChange}
                        error={errors.first_name}
                    />
                    <InputComponent
                        label="Nom:"
                        name="last_name"
                        value={last_name}
                        onChange={handleInputChange}
                        error={errors.last_name}
                    />
                    <InputComponent
                        label="Email:"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        error={errors.email}
                    />
                </div>

                <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                    <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className='rounded border bg-white text-black text-sm font-light py-1 px-2'>
                        Annuler
                    </button>
                    <button type='submit' className='rounded bg-gray-600 hover:bg-gray-700 hover:shadow text-white text-sm py-1 px-2 transform duration-300'>
                        Valider
                    </button>
                </div>
            </form>
        </GenericModal>
    );
}
