import useUsers from '../../../hooks/users/useUsers';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FaCheck, FaUserEdit } from "react-icons/fa";
import { GENERIC_MODAL_ACTIONS } from '../../../context/actionTypes';
import { FiTrash } from 'react-icons/fi';
import ListDisplayer from '../../../utils/ListDisplayer';
import { ImCross } from "react-icons/im";
import { Tooltip } from 'react-tooltip';

const getStatusPoint = (status) => {
    return (
        <div className='flex items-center gap-2'>
            <div className={`rounded-full h-2 w-2 ${status ? 'bg-response-positive' : 'bg-response-medium'}`} />
            {status ? 'Actif' : 'Inactif'}
        </div>
    );
}

export default function Users() {
    const [{ users, companies }, { loadUsers, createUser }] = useUsers();

    const columnsOnSummary = [
        {
            label: 'Prénom',
            key: 'first_name',
            className: 'font-semibold truncate',
            colSpan: 2,  // Augmente l'espace pour Prénom
        },
        {
            label: 'Nom',
            key: 'last_name',
            className: 'font-semibold truncate',
            colSpan: 2,  // Augmente l'espace pour Nom
        },
        {
            label: 'Email',
            key: 'email',
            className: 'flex-1 truncate',
            colSpan: 3,  // Donne plus d'espace à l'email
        },
        {
            label: 'Entreprise',
            key: 'company_name',
            className: 'truncate',
            colSpan: 2,
        },
        {
            label: 'Statut',
            key: 'status',
            colSpan: 1,
            className: 'truncate',
            template: (data) => (
                <div data-tooltip-id="users-tooltip" data-tooltip-content={data.status ? 'Utilisateur actif' : 'Utilisateur inactif'}>
                    {getStatusPoint(data.status)}
                </div>
            ),
        },
        {
            label: 'Admin',
            key: 'is_admin',
            colSpan: 1,  // Réduit l'espace pour rapprocher les colonnes
            className: 'truncate',
            template: (data) => (
                <div className='w-full flex items-center justify-start pl-4'>
                    {data.is_admin ? <FaCheck className="text-blue-400" /> : <ImCross className="text-red-400" />}
                </div>
            ),
        },
        {
            label: 'Créée le',
            key: 'created_at',
            type: 'date',
            colSpan: 1,  // Réduit l'espace pour rapprocher les colonnes
            className: 'truncate',
        },
    ];

    const actionsMenu = [
        {
            action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_ADMIN,
            label: 'Modifier',
            enabled: (e) => true,
            Icon: FaUserEdit,
        },
        {
            action: GENERIC_MODAL_ACTIONS.USER_DELETE,
            label: 'Supprimer',
            enabled: (e) => true,
            Icon: FiTrash,
        }
    ];

    return (
        <div className='pt-4 px-4 text-left flex flex-col max-h-screen overflow-hidden text-base '>
            <div className='flex'>
                <button
                    className="btn-transition bg-gray-600 hover:bg-opacity-75 mb-4"
                    onClick={() => createUser()}
                >
                    <AiOutlinePlusCircle className="h-5 w-5" />
                    <div>Ajouter un administrateur</div>
                </button>
            </div>
            <ListDisplayer
                columnsOnSummary={columnsOnSummary}
                items={users.map((user) => ({
                    ...user,
                    company_name: companies.find((company) => company.id === user.company_id)?.name
                }))}
                reload={loadUsers}
                actionsMenu={actionsMenu}
                defaultSortKey="created_at"
            />
            <Tooltip
                id="users-tooltip"
                style={{ fontSize: "0.75rem", zIndex: 9999 }}
                place='left'
            />
        </div>
    )
}
