import { useEffect, useState } from "react";
import { useAppContext } from "../../context/Context";
import api from "../../utils/api";
import { setBottomBarError } from '../../utils/Functions';

export default function useDashboard(startDate, endDate) {
  const [{ user }] = useAppContext(); // Récupération des informations utilisateur depuis le contexte global de l'application
  const [_, dispatch] = useAppContext();

  const [dataPoints, setUsageData] = useState([]);  // Initialisation en tant que tableau vide
  const [sale, setSale] = useState(null);             // Stocke les informations du commercial
  const [feedbackStats, setFeedbackStats] = useState(null); // Stocke les statistiques des feedbacks
  const [planUsage, setPlanUsage] = useState(null); // Stocke les informations d'utilisations du plan

  // Logs pour vérifier les dates initiales
  console.log("Initial start date:", startDate);
  console.log("Initial end date:", endDate);

  // Effet qui s'exécute lorsque les valeurs `user.company_id`, `startDate`, ou `endDate` changent
  useEffect(() => {
    if (user?.company_id && startDate && endDate) {
      console.log("Triggering data fetch with:", {
        company_id: user.company_id,
        startDate,
        endDate,
      });
      init(); // Initialisation des données à chaque changement de l'utilisateur ou des dates
    }
  }, [user?.company_id, startDate, endDate]);

  // Effet qui s'exécute lorsque la valeur `user.company_id` change
  useEffect(() => {
    if (user?.company_id) {
      console.log("Triggering general company data fetch with:", {
        company_id: user.company_id,
      });
      initGeneralCompanyData(); // Initialisation des données à chaque changement de l'utilisateur
    }
  }, [user?.company_id]);

  // Fonction d'initialisation qui fait les appels API nécessaires pour obtenir les données du tableau de bord
  const init = async () => {
    try {
      // Appel à l'API pour récupérer les données d'utilisation (nombre de requêtes sur la période donnée)
      console.log(`Fetching requests data from API with start_date=${startDate} and end_date=${endDate}`);
      const response = await api.get(
        `/company/${user.company_id}/requests/totals?start_date=${startDate}&end_date=${endDate}`
      );
      console.log("Requests data fetched:", response.data);

      const entries = Object.entries(response.data)
        .map(([date, queries]) => ({ date, queries }))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      setUsageData(entries); // Mise à jour de l'état avec les données renvoyées par l'API

      // Appel à l'API pour récupérer les informations du commercial associé à la société de l'utilisateur
      console.log(`Fetching sales representative data for company_id=${user.company.salesrep_id}`);
      const saleResponse = await api.get(`/salesrep/${user.company.salesrep_id}`);
      console.log("Sales representative data fetched:", saleResponse.data);
      setSale(saleResponse.data); // Mise à jour de l'état avec les données du commercial

      // Appel à l'API pour récupérer tous les feedbacks de l'utilisateur
      console.log("Fetching all feedbacks data");
      const feedbackResponse = await api.get('/feedback/all');
      console.log("Feedbacks data fetched:", feedbackResponse.data);
      
      const filteredFeedbacks = filterFeedbacksByDate(feedbackResponse.data, startDate, endDate);
      console.log("Filtered feedbacks data:", filteredFeedbacks);
      
      const stats = getFeedbackStats(filteredFeedbacks, startDate, endDate);
      console.log("Feedback stats calculated:", stats);
      setFeedbackStats(stats); // Filtrage des feedbacks par date et mise à jour de l'état
    } catch (error) {
      console.error("Error fetching dashboard data:", error); // Gestion des erreurs d'API
    }
  };

  const initGeneralCompanyData = async() => {
    // Appel à l'API pour récupérer le nombre de requêtes utilisées sur le plan
    try {
      
    
    console.log("Fetching plan usage");
    const planUsageResponse = await api.get(`/company/${user.company_id}/count`);
    console.log("Plan usage data fetched:", planUsageResponse.data);
    setPlanUsage(planUsageResponse.data);}
    catch (error) {
      console.error(error)
      setBottomBarError(dispatch, error)

    }
  }

  // Fonction pour filtrer les feedbacks en fonction de la période sélectionnée
  function filterFeedbacksByDate(feedbacks, startDate, endDate) {
    const start = new Date(startDate); // Conversion de la date de début en objet Date
    const end = new Date(endDate);     // Conversion de la date de fin en objet Date

    // Filtrage des feedbacks dans la plage de dates et log des résultats
    const filteredFeedbacks = feedbacks.filter(feedback => {
      const feedbackDate = new Date(feedback.created_at); // Conversion de la date de création du feedback en objet Date
      return feedbackDate >= start && feedbackDate <= end; // Filtrage des feedbacks dans la plage de dates
    });

    console.log(`Filtered feedbacks between ${startDate} and ${endDate}:`, filteredFeedbacks);
    return filteredFeedbacks;
  }

  // Fonction pour calculer les statistiques des feedbacks filtrés par la période sélectionnée
  function getFeedbackStats(feedbacks, startDate, endDate) {
    const totalCountFeedbacks = feedbacks.length; // Nombre total de feedbacks pour la période actuelle
    console.log("Total feedbacks in current period:", totalCountFeedbacks);

    let noGrade = 0;
    let goodGrade = 0;
    let mediumGrade = 0;
    let badGrade = 0;

    // Calcul de la durée de la période actuelle en jours
    const periodDuration = Math.ceil((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24)) + 1;
    console.log("Period duration (days):", periodDuration);

    // Calcul de la période précédente
    const previousPeriodEndDate = new Date(new Date(startDate).getTime() - 1); // Le jour avant le startDate de la période actuelle
    const previousPeriodStartDate = new Date(previousPeriodEndDate.getTime() - (periodDuration - 1) * (1000 * 60 * 60 * 24));
    console.log("Previous period start date:", previousPeriodStartDate.toISOString());
    console.log("Previous period end date:", previousPeriodEndDate.toISOString());

    // Compter le nombre de feedbacks traités dans la période actuelle
    const totalHandledFeedbacks = feedbacks.filter(fb => {
      const isHandled = fb.handled_at && new Date(fb.handled_at) >= new Date(startDate) && new Date(fb.handled_at) <= new Date(endDate);
      console.log(`Feedback ID ${fb.id}: handled = ${isHandled}`);
      return isHandled;
    }).length;

    console.log("Total handled feedbacks in current period:", totalHandledFeedbacks);

    // Classer chaque feedback en fonction de sa note (face)
    feedbacks.forEach(feedback => {
      switch (feedback?.data?.face) {
        case 'good':
          goodGrade++;
          break;
        case 'medium':
          mediumGrade++;
          break;
        case 'bad':
          badGrade++;
          break;
        default:
          noGrade++;
          break;
      }
    });

    console.log("Feedback grades distribution:", { noGrade, goodGrade, mediumGrade, badGrade });

    // Filtrer les feedbacks de la période précédente
    const previousPeriodFeedbacks = filterFeedbacksByDate(feedbacks, previousPeriodStartDate.toISOString(), previousPeriodEndDate.toISOString());
    console.log("Feedbacks in previous period:", previousPeriodFeedbacks);

    const totalHandledFeedbacksPreviousPeriod = previousPeriodFeedbacks.filter(fb => {
      const isHandled = fb.handled_at && new Date(fb.handled_at) >= previousPeriodStartDate && new Date(fb.handled_at) <= previousPeriodEndDate;
      console.log(`Previous Feedback ID ${fb.id}: handled = ${isHandled}`);
      return isHandled;
    }).length;

    console.log("Total handled feedbacks in previous period:", totalHandledFeedbacksPreviousPeriod);

    // Calculer le pourcentage d'augmentation des feedbacks traités pour la période actuelle par rapport à la période précédente
    const percentageIncreaseFeedbacksHandled = totalHandledFeedbacksPreviousPeriod === 0
    ? (totalHandledFeedbacks > 0 ? 100 : 0) // Si aucun feedback traité le mois précédent et des feedbacks ce mois-ci, c'est une augmentation de 100%
    : ((totalHandledFeedbacks - totalHandledFeedbacksPreviousPeriod) / totalHandledFeedbacksPreviousPeriod) * 100;

    console.log("Percentage increase in handled feedbacks this month:", percentageIncreaseFeedbacksHandled.toFixed(2), "%");

    // Retourner les statistiques calculées, y compris les feedbacks traités et leur augmentation
    return {
      totalCountFeedbacks,  // Total des feedbacks pour la période actuelle
      noGrade,              // Nombre de feedbacks sans note
      goodGrade,            // Nombre de feedbacks positifs
      mediumGrade,          // Nombre de feedbacks moyens
      badGrade,             // Nombre de feedbacks négatifs
      totalHandledFeedbacks, // Nombre total de feedbacks traités pour la période actuelle
      percentageIncreaseFeedbacksHandledThisMonth: percentageIncreaseFeedbacksHandled, // Pourcentage d'augmentation des feedbacks traités par rapport à la période précédente
      totalFeedbacksHandledThisMonth: totalHandledFeedbacks, // Nombre total de feedbacks traités ce mois-ci
      totalNewFeedbacksThisMonth: totalCountFeedbacks - previousPeriodFeedbacks.length // Nouveaux feedbacks par rapport à la période précédente
    };
  }

  // Retour d'un tableau contenant un seul objet avec toutes les données nécessaires pour le tableau de bord
  return [{
    dataPoints,          // Points de données pour le graphique des demandes
    sale,                // Informations sur le commercial
    company_uuid: user?.company?.uuid || null, // UUID de la société
    feedbackStats,       // Statistiques des feedbacks pour la période sélectionnée
    planUsage,           // Informations sur l'utilisation du plan
  }];
}
