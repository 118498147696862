import React, {Key, useRef, useState} from "react";

interface Option<T> {
    value: T;
    label: string;
    icon?: React.ReactNode;
    key: Key
}

interface Props<T> {
    options: Option<T>[];
    defaultValue: Key | null | undefined;
    onChange: (selectedOption: T) => void;
}

/**
 * @param options: La liste des options à afficher
 * @param defaultValue: La valeur par défaut à sélectionner
 * @param onChange: Une fonction appelée lorsque la valeur est modifiée par l'utilisateur
 * */
export default function ButtonDropdown<T>({options, defaultValue, onChange}: Props<T>) {
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options.find(option => option.key === defaultValue));
    const buttonRef = useRef(null);

    const handleChange = (newSelectedOption: Option<T>) => {
        if (newSelectedOption !== selectedOption) {
            onChange(newSelectedOption.value);
            setSelectedOption(newSelectedOption);
        }
        setShowOptions(false);
    }

    return <div className="relative flex flex-col items-end">
        <div className="w-full max-w-xs min-w-44">
            {/* Bouton principal qui affiche l'option sélectionnée */}
            <button
                ref={buttonRef}
                className="w-full p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-virteem-darkBlueLight text-black text-sm flex justify-between items-center"
                onClick={() => setShowOptions(!showOptions)}
            >
          <span className="flex items-center gap-2">
            {selectedOption?.icon}
              {selectedOption?.label}
          </span>
                <svg
                    className={`w-4 h-4 transform ${showOptions ? 'rotate-180' : ''}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"/>
                </svg>
            </button>

            {/* Liste des options affichées comme boutons lorsque showOptions est true */}
            {showOptions && (
                <div
                    className="absolute top-full right-0 z-10 bg-white border border-gray-300 rounded-md shadow-lg mt-2"
                    style={{width: buttonRef.current ? `${buttonRef.current.offsetWidth}px` : 'auto'}}
                >
                    {options.map((option) => (
                        <button
                            key={option.key}
                            className={`w-full text-left px-4 py-2 text-sm flex items-center gap-2 focus:outline-none ${
                                selectedOption.key === option.key ? 'bg-perso-blue text-white' : 'hover:bg-gray-100 text-gray-900'
                            }`}
                            onClick={() => handleChange(option)}
                        >
                            {option.icon}
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    </div>
}