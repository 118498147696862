import GenericModal from '../../../utils/GenericModal';
import { ACTION } from '../../../context/actionTypes';
import useDragAndDropDocuments from '../../../hooks/files/useDragAndDropDocuments';
import { BiSolidTrash } from "react-icons/bi";
import { useRef } from 'react';
import { FaFileUpload, FaRegFilePdf } from 'react-icons/fa';
import { ExtensionFile } from '../../../types/types';
import { MdError, MdFileUpload } from 'react-icons/md';
import { FaCirclePause, FaRegCircleCheck } from "react-icons/fa6";

export default function DragAndDropDocumentsModal(props) {
    const [{
        documents,
        uploadStatus,
        uploading
    }, {
        uploadDocuments,
        dispatch,
        deleteFile,
        addFile,
        handleDrop,
        handleDragOver
    }] = useDragAndDropDocuments(props);
    const fileInput = useRef(null)

    const getTypeIcon = (file, statusInfo) => {
        let icon

        switch (file.type) {
            case ExtensionFile.PDF:
                icon = <FaRegFilePdf className='w-6 h-6 text-gray-500' />
                break
        }
        return (<div className="flex place-items-center gap-2 w-full">
            <div>{icon}</div>
            <span className="truncate flex-grow w-full">{file.name}</span>
            <div className=' w-5 flex-shrink-0'>
                {uploadStatus.length > 0 ? (
                    getStatusIcon(statusInfo?.status)
                ) : (
                    <div onClick={() => deleteFile(file.name)} className="cursor-pointer"><BiSolidTrash className='w-6 h-6 text-gray-300 hover:text-red-400 transform duration-300' /></div>
                )}
            </div>
        </div>
        )
    }

    const getStatusIcon = (status) => {
        switch (status) {
            case 'Uploading':
                return <div className=' animate-bounce '><MdFileUpload className=' w-5 h-5' /></div>
            case 'Success':
                return <div><FaRegCircleCheck className=' text-green-500 w-5 h-5' /></div>
            case 'Error':
                return <div><MdError className=' text-red-500 w-5 h-5' /></div>
            default:
                return <div className=' animate-pulse'><FaCirclePause className=' text-yellow-600 w-5 h-5' /></div>
        }
    }

    console.log(uploadStatus);

    return (
        <GenericModal
            className="w-3/4 lg:w-1/3 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={uploadStatus.length === 0}
            exitClick={uploadStatus.length === 0 && documents.length === 0}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className=""
            >
                <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Ajouter des Documents</div>
                <div className="mt-4 px-4" >
                    {uploadStatus.length <= 0 && (
                        <>
                            <div className=" border border-blue-600 bg-blue-50 w-full place-self-center lg:mx-auto rounded-lg space-y-4 " >
                                <div onClick={() => fileInput.current.click()} className={`${documents.length === 4 ? 'opacity-25' : 'cursor-pointer'} flex flex-col place-items-center space-y-4 py-4`}>
                                    <FaFileUpload className='h-8 w-8 text-blue-600' />
                                    <span className="text-gray-600" > Glissez & Déposez vos fichiers ici (.pdf)</span>
                                    <div className='px-4 py-3 rounded-xl bg-gray-200 hover:bg-gray-300'>Choisir des fichiers</div>
                                </div>
                            </div>
                            <span className=" text-xs" >Fichiers pdf uniquement, 50Mo maximum </span>
                        </>
                    )}
                    {documents.length > 0 && (
                        <div className=''>
                            <div className='font-bold text-black pt-4'>Fichiers à Uploader :</div>
                            <div className="space-y-2 py-2 max-h-56 overflow-y-auto">
                                {documents.map((file) => {
                                    const statusInfo = uploadStatus.find(status => status.fileName === file.name);
                                    return (
                                        <div key={file.name} className='flex items-center justify-between py-3 px-3 rounded border border-gray-400 overflow-hidden'>
                                            <div className='flex-grow p-1 w-full'>{getTypeIcon(file, statusInfo)}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <input multiple ref={fileInput} type="file" accept=".pdf" onChange={(event) => addFile(event.target)} className="hidden" />
                </div>

                <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                    {uploading ? (
                        <div>Upload en cours ...</div>
                    ) : (
                        <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className='rounded border bg-white text-black text-sm font-light py-1 px-2'>
                            {uploadStatus.length <= 0 ? "Annuler" : "Terminer"}
                        </button>
                    )}
                    {uploadStatus.length <= 0 && (
                        <button onClick={() => uploadDocuments()} className='rounded bg-gray-600 hover:bg-gray-700 hover:shadow text-white text-sm py-1 px-2 transform duration-300'>
                            Valider
                        </button>
                    )}

                </div>
            </div>
        </GenericModal>
    );
};