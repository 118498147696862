import useCompanies from '../../../hooks/companies/useCompanies';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { FiTrash } from 'react-icons/fi';
import { GENERIC_MODAL_ACTIONS } from '../../../context/actionTypes';
import ListDisplayer from '../../../utils/ListDisplayer';
import { GiChoice } from 'react-icons/gi';
import { CgOptions } from 'react-icons/cg';
import { TbHttpDelete, TbLayoutGridRemove, TbUserPlus } from 'react-icons/tb';
import { Tooltip } from 'react-tooltip';
import { humanFileSize } from '../../../utils/Functions';
import { IoBagRemoveOutline } from 'react-icons/io5';

const getStatusPoint = (status) => {
  return (
    <div className='flex items-center gap-2'>
      <div
        className={`rounded-full h-2 w-2 ${
          status ? 'bg-response-positive' : 'bg-response-medium'
        }`}
      />
      {status ? 'Active' : 'Inactive'}
    </div>
  );
};

export default function Companies() {
  const [{ companies }, { loadCompanies, createCompany }] = useCompanies();

  const columnsOnSummary = [
    {
      label: 'Nom',
      key: 'name',
      className: 'font-semibold',
      colSpan: 2,
    },
    {
      label: 'Email',
      key: 'email',
      colSpan: 3,
    },
    {
      label: 'Secteur',
      key: 'sector',
      colSpan: 2,
    },
    {
      label: 'Zone Géographique',
      key: 'geographical_zone',
      colSpan: 3,
    },
    {
      label: 'Créée le',
      key: 'created_at',
      type: 'date',
      colSpan: 2,
    },
    {
      label: "Stockage",
      key: "int_size",
      colSpan: 1,
      template: (data) =>
        isNaN(data.int_size) ? "N/A" : humanFileSize(data.int_size),
    },

    {
      label: 'Status',
      key: 'status',
      colSpan: 1,
      template: (data) => (
        <div
          data-tooltip-id='companies-tooltip'
          data-tooltip-content={
            data.status ? 'Entreprise active' : 'Entreprise inactive'
          }
        >
          {getStatusPoint(data.status)}
        </div>
      ),
    },
  ];

  const actionsMenu = [
    {
      action: GENERIC_MODAL_ACTIONS.MANAGE_COMPANY_PLAN,
      label: 'Gérer le plan',
      enabled: (e) => true,
      Icon: CgOptions,
    },
    {
      action: GENERIC_MODAL_ACTIONS.MANAGE_COMPANY_OPTIONS,
      label: 'Gérer les options',
      enabled: (e) => true,
      Icon: GiChoice,
    },
    {
      action: GENERIC_MODAL_ACTIONS.MANAGE_COMPANY_SALES,
      label: 'Associer un commercial',
      enabled: (e) => true,
      Icon: TbUserPlus,
    },
    {
      action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_COMPANY,
      label: 'Modifier',
      enabled: (e) => true,
      Icon: FaEdit,
    },
    {
      action: GENERIC_MODAL_ACTIONS.DELETE_REQUESTS,
      label: 'Réinitilialiser le plan',
      enabled: (e) => true,
      Icon: TbHttpDelete,
    },
    {
      action: GENERIC_MODAL_ACTIONS.DELETE_SENSITIVE_DATA_REQUESTS,
      label: 'Supprimer les données sensibles des requêtes',
      enabled: (e) => true,
      Icon: IoBagRemoveOutline,
    },
    // {TODO Delete Company}
    // {
    //   action: GENERIC_MODAL_ACTIONS.DELETE_COMPANY,
    //   label: 'Supprimer',
    //   enabled: (e) => true,
    //   Icon: FiTrash,
    // },
  ];

  return (
    <div className='pt-4 px-4 text-left flex flex-col max-h-screen overflow-hidden text-base '>
      <div className='flex'>
        <button
          className='btn-transition bg-gray-600 hover:bg-opacity-75 mb-4'
          onClick={() => createCompany()}
        >
          <AiOutlinePlusCircle className='h-5 w-5' />
          <div>Ajouter une entreprise</div>
        </button>
      </div>
      <ListDisplayer
        columnsOnSummary={columnsOnSummary}
        items={companies}
        reload={loadCompanies}
        actionsMenu={actionsMenu}
        defaultSortKey='created_at'
      />
      <Tooltip
        id='companies-tooltip'
        style={{ fontSize: '0.75rem', zIndex: 9999 }}
        place='left'
      />
    </div>
  );
}
