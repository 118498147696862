import { useEffect, useState } from 'react';
import api from '../../utils/api';
import { useAppContext } from '../../context/Context';
import { ExtensionFile } from '../../types/types';
import { setBottomBarError } from '../../utils/Functions';


export default function useDragAndDropDocuments(props) {
    const { reload } = props;
    const [_, dispatch] = useAppContext();
    const [documents, setDocuments] = useState([]);
    const [uploadStatus, setUploadStatus] = useState([]);
    const [uploading, setUploading] = useState(false);

    const updateUploadStatus = (fileName, status, message = '') => {
        setUploadStatus(prevStatus => {
            // Check if an entry with the same fileName already exists
            const existingIndex = prevStatus.findIndex(item => item.fileName === fileName);

            if (existingIndex !== -1) {
                // If found, create a new array with the updated item
                return prevStatus.map((item, index) =>
                    index === existingIndex ? { ...item, status, message } : item
                );
            } else {
                // If not found, add the new status to the array
                return [...prevStatus, { fileName, status, message }];
            }
        });
    };

    const uploadDocument = async (file) => {
        updateUploadStatus(file.name, 'Uploading'); // Mark as uploading
        try {
            const formData = new FormData();
            formData.append('new_document', file); // Assuming 'new_document' is the field name expected by your API
            const response = await api.post('/document/', formData);

            // Assuming the API response includes the file name and status
            updateUploadStatus(file.name, 'Success', `Uploaded successfully at ${response.data.uploaded_at}`);
            await reload();
        } catch (error: any) {
            console.error("Failed to upload file", error);
            updateUploadStatus(file.name, 'Error', error.message || 'Unknown error');
            setBottomBarError(dispatch, error);
        }
    };

    const uploadDocuments = async () => {
        setUploading(true);
        for (const file of documents) {
            await uploadDocument(file);
        }
        setUploading(false);
        await reload();
    };

    const removeDocument = async (fileName) => {
        try {
            await api.delete(`/files/${fileName}`);
            setDocuments(documents.filter(file => file.name !== fileName));
        } catch (error) {
            console.error(`Failed to remove file: ${fileName}`, error);
            setBottomBarError(dispatch, error)
        }
    };

    // Utility function to check if a MIME type matches any ExtensionFile enum value
    function isFileTypeValid(mimeType: string): boolean {
        return Object.values<string>(ExtensionFile).includes(mimeType);
    }

    const addFiles = (files: FileList) => {
        const selectedFiles = Array.from(files).slice(0, 40); // Adjust the limit as necessary
        const validFiles = selectedFiles.filter(file => isFileTypeValid(file.type));

        setDocuments(prev => {
            // Create a map of file identifiers from the existing documents for quick lookup
            const existingFilesMap = new Map();
            prev.forEach(file => existingFilesMap.set(file.name, true)); // Use file.name as a unique identifier; adjust as necessary

            // Filter out files that have already been added
            const newFiles = validFiles.filter(file => !existingFilesMap.has(file.name));

            // Combine the previous documents with the new, non-duplicate files
            return [...prev, ...newFiles];
        });
    };


    const addFileFromInput = (target) => {
        if (target.files.length === 0) {
            return;
        }
        addFiles(target.files);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        addFiles(files);
    };

    const handleDragOver = (event) => {
        event.preventDefault(); // This is necessary to allow for the drop event.
    };

    const deleteFile = (name) => {
        setDocuments((prev) => prev.filter((file) => file.name !== name));
    };

    // Ensure to return handleDrop and handleDragOver for use in the component.
    return [{ documents, uploadStatus, uploading }, { uploadDocuments, removeDocument, dispatch, addFile: addFileFromInput, deleteFile, handleDrop, handleDragOver }];
}
