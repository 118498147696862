import React, {useEffect, useState} from "react";
import {User} from "../../../../types/types";
import {useAppContext} from "../../../../context/Context";
import Maintenance from "../../settings/home/Maintenance";
import InfoMessageNote from "../../settings/messageNote/InfoMessageNote";
import useAffichage from "./useAffichage";
// Mock function to simulate fetching user data from an API
export default function Affichage() {
    const [{ isAdminVirteem, user }, __] = useAppContext()
    const [{percentages},{setshowPercentages}]=useAffichage()
    return (
        <main className="flex flex-col gap-2">
            { isAdminVirteem ? (
                <>
                    <h1>Bienvenue, {user.first_name} {user.last_name}!</h1>
                    <br/>
                    <p>Gérez vos options d'affichages.</p>
                    <InfoMessageNote display={ isAdminVirteem ? (
                            <p>Vous avez des permissions <strong>administrateurs Virteem</strong>.</p>
                    ) : (
                        <>
                            <p>Vos permissions dans l'application sont  <strong>limités</strong>.</p>
                            <br/>
                            <p>Si vous souhaitez modifier vos permissions, vous devez contacter votre administrateur entreprise.</p>
                        </>
                    )
                    }/>
                    <div className="flex items-center p-2 rounded text-black hover:bg-gray-600 hover:text-white">
                    <input
                    id="showPercentagesCheckbox"
                      type="checkbox"
                      className=" w-4 h-4  bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
                      checked={percentages}
                      onClick={setshowPercentages}
                    />
                    <label
                      htmlFor="showPercentagesCheckbox"
                      className="w-full ms-2 text-sm font-medium   rounded "
                    >
                      Afficher les pourcentages de similarités avec les sources
                    </label>
                  </div>
                    </>
            ) : (
              <Maintenance/>
            )}
        </main>
    );
};
