import React from "react";
import "./SubmitButton.css";
import { twMerge } from 'tailwind-merge';
interface SubmitButtonProps {
  text: string;
  className?: string;
  onClick?: () => void;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ text, className, onClick }) => {
     return (
    <button className={twMerge("blueSubmitButton", className)} type="submit" onClick={onClick}>
      {text}
    </button>
  );
}
export default SubmitButton;
