import { useEffect, useState } from 'react';
import api from '../../utils/api';
import { useAppContext } from '../../context/Context';
import { setBottomBarError } from '../../utils/Functions';
import { ACTION } from '../../context/actionTypes';

export default function useManageCompanySales({ id: company_id, salesrep_id, reload }) {
    const [_, dispatch] = useAppContext();
    const [sales, setSales] = useState(null)
    const [sale, setSale] = useState(null)

    useEffect(() => {
        const init = async () => {
            try {
                const response = await api.get('/salesrep/all');
                setSales(response.data);
                // setSales([
                //     {
                //         profile_picture: null,
                //         first_name: "Vincent",
                //         last_name: "Wargnier",
                //         email: "vincent@virteem.com",
                //         created_at: "2024-04-02T18:51:14.798000",
                //         updated_at: "2024-04-02T18:51:14.798000",
                //         id: 1
                //     },
                //     {
                //         profile_picture: null,
                //         first_name: "Ugo",
                //         last_name: "Lewbrick",
                //         email: "Ugo@virteem.com",
                //         created_at: "2024-04-02T18:51:14.798000",
                //         updated_at: "2024-04-02T18:51:14.798000",
                //         id: 2
                //     },
                //     {
                //         profile_picture: null,
                //         first_name: "Robert",
                //         last_name: "Perrot",
                //         email: "rob@virteem.com",
                //         created_at: "2024-04-02T18:51:14.798000",
                //         updated_at: "2024-04-02T18:51:14.798000",
                //         id: 3
                //     },
                // ]);
            } catch (error) {
                console.error("Failed to load plan data", error);
                setBottomBarError(dispatch, error)
            }
        };
        init();
    }, []);

    useEffect(() => {
        if (salesrep_id && sales) {
            setSale(sales.find(sale => sale.id === salesrep_id))
        }
    }, [salesrep_id, sales])

    const handleSubmit = async () => {

        try {
            if (sale.id !== salesrep_id) {
                await api.patch(`/company/${company_id}/salesrep`, { new_salesrep_id: sale.id });
                await reload()
            }
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });
        } catch (error: any) {
            console.error("Failed to update plan", error);
            setBottomBarError(dispatch, error);
        }
    }

    return [{
        sale,
        sales
    }, {
        setSale,
        dispatch,
        handleSubmit,
    }];
}
