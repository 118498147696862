import GenericModal from '../../../utils/GenericModal';
import { IoWarning } from "react-icons/io5";
import useDeleteDocument from '../../../hooks/files/useDeleteDocument';

export default function DeleteDocumentModal(props) {
    const { names, ids, reload } = props;
    const [__, { deleteDocuments, toggleMe }] = useDeleteDocument({ ids, reload });

    console.log("DeleteDocumentModal Props:", { names, ids, reload });

    const handleDelete = async () => {
        try {
            await deleteDocuments();
            toggleMe();
            reload(); // Reload the documents after deletion
        } catch (error) {
            console.error("Erreur lors de la suppression des documents:", error);
        }
    };

    return (
        <GenericModal
            className="w-1/2 xl:w-1/3 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={true}
            toggleMe={toggleMe}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm flex items-end gap-1'>
                <IoWarning className='text-red-400 w-5 h-5' />
                Suppression de documents
            </div>
            <div className='p-8'>
                {`Vous êtes sur le point de supprimer les documents suivants :`}
                <ul style={{maxHeight: "calc(75vh - 204px)", overflowY: "auto"}}>
                    {names && names.length > 0 ? names.map((name, index) => (
                        <li key={index}><b>{name}</b></li>
                    )) : "Aucun document sélectionné"}
                </ul>
            </div>
            <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                <button onClick={toggleMe} className='rounded border bg-white text-black text-sm font-light py-1 px-2'>
                    Annuler
                </button>
                <button onClick={handleDelete} className='rounded bg-gray-600 hover:bg-gray-700 hover:shadow text-white text-sm py-1 px-2 transform duration-300'>
                    Continuer
                </button>
            </div>
        </GenericModal>
    );
}
