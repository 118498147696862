import { useEffect, useState } from 'react'
import { FiChevronsRight } from 'react-icons/fi'
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { useDeviceType } from '../../hooks/useDeviceType';

export default function BurgerMenu({ children }) {
    const deviceType = useDeviceType()
    const [collapsed, setCollapsed] = useState(false)

    useEffect(() => {
        setCollapsed(deviceType === 'smartphone')
    }, [deviceType])

    return (
        <>
            <div className={`flex-shrink-0 transition-all transform duration-700 ${collapsed ? " w-0 " : " w-0 md:w-72 "}`} />
            <div
                onClick={() => setCollapsed(!collapsed)}
                className={twMerge(`fixed z-40 left-2 flex-shrink-0 mb-4 py-4 sm:py-1 -top-2 right-0 m-1 sm:m-3 cursor-pointer transform transition-all duration-500 hover:scale-125 w-5 text-perso-darkBlue ${!collapsed && " rotate-180 text-white "}`)}
            >
                <FiChevronsRight className="w-6 h-6  " />
            </div>
            <div className={twMerge(` text-base flex flex-col h-screen flex-shrink-0 fixed z-30 transition-all transform duration-700 bg-perso-darkBlue w-72 ${collapsed ? ` -left-72` : " left-0 "}`)}>
                <div className={twMerge(` flex flex-grow overflow-y-auto flex-col pt-6 space-y-5 `)}>
                    <img src='/images/logo.svg' alt='logo' className='h-20 ' />
                    {children}
                </div>
            </div>
            <Tooltip
                id="doc-tooltip"
                style={{ fontSize: "0.75rem", zIndex: 9999 }}
                place='top'
            />
        </>
    )
}
