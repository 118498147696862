import { useEffect, useState } from 'react';
import api from '../../utils/api';
import { setBottomBarError } from '../../utils/Functions';
import { useAppContext } from '../../context/Context';
import { ACTION, GENERIC_MODAL_ACTIONS } from '../../context/actionTypes';

export default function useManageSalesRep() {
    const [_, dispatch] = useAppContext();
    const [salesReps, setSalesReps] = useState([]);

    useEffect(() => {
        loadSalesReps();
    }, []);

    const createSalesRep = async () => {
        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                props: { reload: loadSalesReps },
                action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_SALES_REP
            }
        })
    }

    const loadSalesReps = async () => {
        try {
            const response = await api.get('/salesrep/all');
            setSalesReps(response.data);
        } catch (error) {
            setBottomBarError(dispatch, error);
        }
    };

    return [{
        salesReps,
    }, {
        loadSalesReps,
        createSalesRep
    }];
}
