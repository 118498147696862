import {IoWarning} from "react-icons/io5";
import GenericModal from "../../../../utils/GenericModal";
import api from "../../../../utils/api";
import {setBottomBarError} from "../../../../utils/Functions";
import {ACTION} from "../../../../context/actionTypes";
import {useAppContext} from "../../../../context/Context";
import {ApiKeySchema} from "./ApiKeys";

export type RemoveAPIKeyCallback = (apiKeyId: number) => void;

interface DeleteApiKeyModalProps {
    apiKey: ApiKeySchema;
    removeApiKey: RemoveAPIKeyCallback
}

export default function DeleteApiKeyModal({apiKey: {name, id}, removeApiKey}: DeleteApiKeyModalProps) {
    const [{user}, dispatch] = useAppContext();

    const deleteApiKey = async () => {
        try {
            await api.delete(`/company/${user.company_id}/api-keys/${id}`);
            removeApiKey(id);
            toggleMe();
        } catch (error) {
            console.error("Failed to delete api key", error);
            // TODO english version
            // setBottomBarError(dispatch, "Failed to delete API key.");
            setBottomBarError(dispatch, error);
        }
    };

    const toggleMe = () => dispatch({type: ACTION.SET_GENERIC_MODAL, payload: null});

    return (
        <GenericModal
            className="w-1/2 xl:w-1/3 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={true}
            toggleMe={toggleMe}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm flex items-end gap-1'>
                <IoWarning className='text-red-400 w-5 h-5'/>Suppression de clé d'API
            </div>
            <div className='p-8'>{`Vous êtes sur le point de supprimer la clé d'API `}<b>{name}</b></div>
            <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                <button onClick={toggleMe} className='rounded border bg-white text-black text-sm font-light py-1 px-2'>
                    Annuler
                </button>
                <button onClick={deleteApiKey} className='rounded bg-gray-600 hover:bg-gray-700 text-white text-sm py-1 px-2 transform duration-300'>
                    Continuer
                </button>
            </div>
        </GenericModal>
    );
}
