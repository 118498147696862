import React from "react";
import { BsChevronDown } from 'react-icons/bs';
import { twMerge } from 'tailwind-merge'

interface FormElementWrapperProps {
    className?: string;
    label?: string;
    labelClassName?: string;
    children: React.ReactNode;
}

function FormElementWrapper({ className, label, labelClassName, children }: FormElementWrapperProps) {
    return (
        <div className={`mb-1 ${className}`}>
            <label className={`block text-black text-xs font-light mb-1 text-left ${labelClassName}`}>
                {label}
            </label>
            {children}
        </div>
    );
}

interface FormElementProps {
    className?: string;
    label?: string;
    name?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
}
export function DatePickerComponent({ className, label, name, value, onChange }: FormElementProps) {
    return (
        <FormElementWrapper className={className} label={label}>
            <input
                type="date"
                name={name}
                value={value}
                onChange={onChange}
                className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
        </FormElementWrapper>
    );
}

interface TextAreaProps {
    className?: string;
    label?: string;
    name?: string;
    value: string;
    rows?: number;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    error?: string;
}
export function TextAreaComponent({ className, label, name, value, rows = 5, onChange, error }: TextAreaProps) {
    const hasError = Boolean(error);
    return (
        <FormElementWrapper className={twMerge(className, hasError ? 'border-red-500' : 'border-gray-300')} label={label}>
            <textarea
                name={name}
                value={value}
                onChange={onChange}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 text-sm leading-tight focus:outline-none focus:shadow-outline"
                rows={rows}
            ></textarea>
            {hasError && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
        </FormElementWrapper>
    );
}

interface DropDownMenuProps {
    className?: string;
    name: string;
    items: any[];
    labelField: string;
    onItemSelected: (item: any) => void;
    currentItem: any;
    label?: string;
    sort?: boolean;
    error?: string;
}
export function DropDownMenuComponent({ className, name, items, labelField, onItemSelected, currentItem, label, sort = false, error }: DropDownMenuProps) {
    const hasError = Boolean(error);

    const handleChange = (event) => {
        const selectedItem = items.find(item => item[labelField] === event.target.value);
        onItemSelected({ ...event, target: { name, value: selectedItem } });
    };

    const sortedItems = sort ? items?.sort((a, b) => a[labelField].localeCompare(b[labelField])) : items;

    return (
        <FormElementWrapper className={className} label={label}>
            <div className="relative">
                <select
                    value={currentItem ? currentItem[labelField] : ""}
                    onChange={handleChange}
                    className={`appearance-none border rounded w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${hasError ? 'border-red-500' : 'border-gray-300'}`}
                >
                    <option value="">Select</option>
                    {sortedItems?.map(item => (
                        <option key={item[labelField]} value={item[labelField]}>
                            {item[labelField]}
                        </option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-700">
                    <BsChevronDown className="w-4 h-4" />
                </div>
                {hasError && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
            </div>
        </FormElementWrapper>
    );
}



interface InputProps {
    className?: string;
    label?: string;
    labelClassName?: string;
    inputClassName?: string;
    name?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    type?: string;
    error?: string;
}
export function InputComponent({ className, labelClassName, inputClassName, label, name, value, onChange, type = "text", error }: InputProps) {
    const hasError = Boolean(error);

    return (
        <div className={` ${className}`}>
            <FormElementWrapper labelClassName={labelClassName} label={label}>
                {/* <label htmlFor={name} className={twMerge(`block text-sm font-medium text-gray-700 ${labelClassName}`)}>{label}</label> */}
                <input
                    id={name}
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={twMerge(`
                    appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                    focus:outline-none focus:shadow-outline
                    ${inputClassName} 
                    ${hasError ? 'border-red-500' : 'border-gray-300'}
                `)}
                />
                {hasError && <p className="text-red-500 text-xs italic mt-1">{error}</p>}
            </FormElementWrapper>
        </div>
    );
}

interface SwitchProps {
    className?: string;
    label?: string;
    name?: string;
    checkedLabel?: string;
    uncheckedLabel?: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

export function SwitchComponent({ label, name, checked, className, checkedLabel, uncheckedLabel, onChange }: SwitchProps) {
    const toggleSwitch = (event) => onChange({ ...event, target: { name, value: !checked } });

    return (
        <div className={`flex gap-2 items-center ${className}`}>
            {label && <div>{label}</div>}
            <div className="relative inline-flex items-center">
                <input
                    type="checkbox"
                    name={name}
                    className="sr-only peer"
                    checked={checked}
                    readOnly
                />
                <div
                    className="w-10 h-5 flex items-center bg-gray-200 rounded-full peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-400 peer-checked:bg-blue-600 cursor-pointer"
                    onClick={toggleSwitch}
                >
                    <div className={`transform duration-300 ease-in-out ${checked ? ' translate-x-5' : 'translate-x-0.5'
                        } w-4 h-4 bg-white rounded-full`}></div>
                </div>
            </div>
            {(checkedLabel || uncheckedLabel) && <div>{checked ? checkedLabel : uncheckedLabel}</div>}
        </div>
    );
}


