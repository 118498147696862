import { useState } from "react";
import { useAppContext } from "../context/Context";
import { checkEmail, setBottomBarError } from "../utils/Functions";
import api from "../utils/api";
import { ACTION } from "../context/actionTypes";
import { User } from "../types/types";
import { checkToken } from "./useAuthentication";


interface UseSignModalResult {
    state: {
        signForm: {
            [key: string]: {
                value: string;
                error: string;
            };
        };
        user: User | null;
        isLoggedIn: Boolean;
        isAwaitingAuth:Boolean;
    };
    actions: {
        handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
        handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    };
}

const initForm = {
    email: { value: '', error: '' },
    password: { value: '', error: '' },
}

export default function useSignIn(): [UseSignModalResult['state'], UseSignModalResult['actions']] {
    const [{ isLoggedIn,isAwaitingAuth, user }, dispatch] = useAppContext()
    const [signForm, setSignForm] = useState(initForm)

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSignForm((old) => ({ ...old, [name]: { ...signForm[name], value } }))
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const updatedFields = {
            email: {
                ...signForm.email,
                error: !checkEmail(signForm.email.value) ? 'Email invalide' : ''
            },
            password: {
                ...signForm.password,
                error: signForm.password.value.length <= 0 ? 'Veuillez renseigner un mot de passe' : ''
            }
        };

        const isFormValid = Object.values(updatedFields).every(field => !field.error);

        setSignForm(updatedFields)
        if (isFormValid) {
            logIn()
        }
    };

    const logIn = async () => {
        console.log("Logging in")
        try {
            const { email, password } = signForm;

            if (!email.value || !password.value) {
                console.error("Email and password are required");
                return;
            }

            const formData = new URLSearchParams();
            formData.append('username', email.value);
            formData.append('password', password.value);

            const response = await api.post('/user/login', formData.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            await checkToken(dispatch)

            dispatch({
                type: ACTION.LOAD_SESSION,
                payload: { user: response.data }
            });
            // window.location.reload();

        } catch (err) {
            setBottomBarError(dispatch, err)
        }
    }


    return [{
        signForm,
        isLoggedIn,
        isAwaitingAuth,
        user
    }, {
        handleSubmit,
        handleInputChange
    }]
}
