import useOptions from '../../../hooks/options/useOptions'; // Adjust import path as necessary
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FaEdit } from "react-icons/fa";
import { FiTrash } from 'react-icons/fi';
import { GENERIC_MODAL_ACTIONS } from '../../../context/actionTypes';
import ListDisplayer from '../../../utils/ListDisplayer';

export default function Options() {
    const [{ options }, { loadOptions, createOption }] = useOptions();

    const columnsOnSummary = [
        {
            label: 'Name',
            key: 'name',
            className: 'font-semibold',
            colSpan: 2,
        },
        {
            label: 'Description',
            key: 'description',
            colSpan: 3,
        },
        {
            label: 'Price',
            key: 'price',
            colSpan: 1,
        },
        {
            label: 'Created At',
            key: 'created_at',
            type: 'date',
            colSpan: 1,
        },
    ]

    const actionsMenu = [
        {
            action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_OPTION,
            label: 'Modifier',
            enabled: (e) => true,
            Icon: FaEdit,
        },
        {
            action: GENERIC_MODAL_ACTIONS.DELETE_OPTION,
            label: 'Supprimer',
            enabled: (e) => true,
            Icon: FiTrash,
        }
    ];

    return (
        <div className='pt-4 px-4 text-left flex flex-col max-h-screen overflow-hidden text-base'>
            <div className='flex'>
                <button
                    className="btn-transition bg-gray-600 hover:bg-opacity-75 mb-4"
                    onClick={() => createOption()}
                >
                    <AiOutlinePlusCircle className="h-5 w-5" />
                    <div>Créer une option</div>
                </button>
            </div>
            <ListDisplayer
                 columnsOnSummary={columnsOnSummary}
                 items={options}
                 actionsMenu={actionsMenu}
                 reload={loadOptions}
                 defaultSortKey="created_at"
             />
        </div>
    );
}


