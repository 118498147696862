interface CategoryProps {
    title: string;
    children: React.ReactNode;
}

export default function Category({title, children}: CategoryProps) {
    return <div className="text-base p-4">
        <h2 className="text-xl font-semibold mb">{title}</h2>
        <hr className="mb-4"/>
        {children}
    </div>
}