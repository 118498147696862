export interface Company {
    name: string;
    email: string;
    sector: string;
    status: string;
    id: number;
    plan_id: number | null;
    salesrep_id: number | null;
    uuid: string;
    created_at: string | null;
}

export interface User {
    first_name: string;
    last_name: string;
    email: string;
    status: string;
    id: number;
    company_id: number;
    is_virteem_admin: boolean;
    company: Company;
}

export enum ExtensionFile {
    MSWORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PDF = 'application/pdf',
    TXT = 'text/plain',
    JSON = 'application/json',
}

export const FILE_REGEX = /[\w-.,'’()éèàùçäöüß]+?\.(txt|md|html|pdf|docx|pptx)/gi;


