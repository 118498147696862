import { useState } from 'react';
import api from '../../utils/api';  // Assurez-vous que le chemin est correct pour accéder à l'instance de l'API
import { setBottomBarError, setBottomBarSucces } from '../../utils/Functions';
import { useAppContext } from '../../context/Context';

interface UseAddKnowledgeBaseReturn {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: () => void;
}

export default function useAddKnowledgeBase(): UseAddKnowledgeBaseReturn {
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [_, dispatch] = useAppContext();

  const handleSubmit = async () => {
    try {
      // Envoyer les paramètres en tant que query params
      const response = await api.post(`/document/knowledge-base?title=${encodeURIComponent(title)}&content=${encodeURIComponent(content)}`);
      setBottomBarSucces(dispatch,'Document ajouté à la base de connaissances avec succès !')
      console.log('Document ajouté à la base de connaissances avec succès:', response.data);
    } catch (error) {
      console.error('Erreur lors de l\'ajout à la base de connaissances:', error);
      setBottomBarError(dispatch, error);  // Affiche l'erreur dans la barre inférieure
    }
  };

  return {
    title,
    setTitle,
    content,
    setContent,
    handleSubmit,
  };
}
