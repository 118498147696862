import { useState } from 'react';
import api from '../../utils/api';
import { useAppContext } from '../../context/Context';
import { ACTION } from '../../context/actionTypes';
import { setBottomBarError } from '../../utils/Functions';

export default function useCreateOrUpdatePlan({ name, description, price, quota_usage, id, document_limit, reload }) {
    const [plan, setPlan] = useState({ name, description, price, quota_usage, id, document_limit });
    const [errors, setErrors] = useState({} as any);
    const [_, dispatch] = useAppContext();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const updatedValue = ['price', 'quota_usage', 'document_limit'].includes(name) ? Number(value) : value;
        setPlan(prev => ({ ...prev, [name]: updatedValue }));
    };

    const validateForm = () => {
        let curErrors = {};

        if (!plan.name) {
            curErrors = { ...curErrors, name: "Le nom du plan est requis" };
        }

        if (!plan.description) {
            curErrors = { ...curErrors, description: "La description du plan est requise" };
        }

        if (!plan.price || plan.price <= 0) {
            curErrors = { ...curErrors, price: "Le prix du plan doit être supérieur à 0" };
        }

        if (!plan.quota_usage || plan.quota_usage < 0) {
            curErrors = { ...curErrors, quota_usage: "Le quota d'utilisation doit être supérieur ou égal à 0" };
        }

        if (!plan.document_limit || plan.document_limit < 0) {
            curErrors = { ...curErrors, document_limit: "La limite de documents doit être supérieure ou égale à 0" };
        }

        return curErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const curErrors = validateForm();

        setErrors(curErrors);

        if (Object.keys(curErrors).length > 0) return;

        try {
            if (id) {
                await api.patch(`/plan/${id}`, plan);
            } else {
                await api.post('/plan/', { new_plan: { ...plan } });
            }
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });
            await reload();
        } catch (error) {
            console.error("Failed to create/update plan", error);
            setErrors({ submit: "Impossible de soumettre les données du plan." });
            setBottomBarError(dispatch, error);
        }
    };

    return [{
        plan,
        errors,
    }, {
        dispatch,
        setOption: setPlan,
        handleInputChange,
        handleSubmit
    }];
}
