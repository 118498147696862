import TitleAndSubTitle from "../TitleAndSubTitle";
import {ACTION, GENERIC_MODAL_ACTIONS} from "../../../../context/actionTypes";
import {IoRemoveCircleOutline} from "react-icons/io5";
import React from "react";
import {useAppContext} from "../../../../context/Context";
import Category from "./Category";
import ActionContainer from "./ActionContainer";

export default function CompanyAdministration() {
    const [, dispatch] = useAppContext()

    return <main className="flex flex-col gap-2">
        <TitleAndSubTitle
            h1="Administration de l'entreprise"
            h2="Gérez les tâches avancées d'administration de l'entreprise"
        />

        <div className="flex flex-col gap-4">
            <Category title="Gestion des retours">
                <ActionContainer text="Supprimer définitivement tous les retours des collaborateurs">
                    <button
                        className='btn-transition-l bg-perso-blue hover:bg-opacity-75'
                        onClick={() => {
                            dispatch({
                                type: ACTION.SET_GENERIC_MODAL,

                                payload: {
                                    action: GENERIC_MODAL_ACTIONS.DELETE_FEEDBACKS
                                }
                            })
                        }}
                    >
                        <IoRemoveCircleOutline className='h-5 w-5'/>
                        <div>Supprimer les retours</div>
                    </button>
                </ActionContainer>
            </Category>
        </div>
    </main>
}