import GenericModal from '../../utils/GenericModal'
import useFeedbacks from '../../hooks/chat/useFeedbacks'
import { ACTION } from '../../context/actionTypes'
import { TextAreaComponent } from '../../utils/FormComponents'
import { twMerge } from 'tailwind-merge'

export default function FeedBackModal(props) {
    const [{ feedback, face }, { setFeedback, handleSubmit, dispatch, setFace }] = useFeedbacks(props)
    return (
        <GenericModal
            className="w-full lg:w-2/5 xl:w-1/2 2xl:w-1/4 overflow-hidden text-base text-black p-4 bg-white rounded-lg"
            showCloseButton={true}
            exitClick={false}
            blurBackGround={true}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >

            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Laisser un avis</div>
            <div className=' text-6xl flex items-center gap-6 justify-center w-full pt-6 pb-4'>
                <div onClick={() => setFace('good')} className={twMerge(` opacity-35 hover:opacity-100 transform duration-300 cursor-pointer ${face === 'good' && 'opacity-100'}`)}><img src='/green.svg'/></div>
                <div onClick={() => setFace('medium')} className={twMerge(` opacity-35 hover:opacity-100 transform duration-300 cursor-pointer ${face === 'medium' && 'opacity-100'}`)}><img src='/orange.svg'/></div>
                <div onClick={() => setFace('bad')} className={twMerge(` opacity-35 hover:opacity-100 transform duration-300 cursor-pointer ${face === 'bad' && 'opacity-100'}`)}><img src='/red.svg'/></div>
            </div>
            <div className='text-sm px-2 text-light'>Expliquez-nous plus en détails (optionnel) :</div>
            <div className=' overflow-y-auto max-h-96 p-2'>
                <TextAreaComponent
                    className='w-full text-white'
                    // label='Expliquez-nous plus en détails (optionnel) :'
                    name='feedback'
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                />
            </div>
            <div className='flex justify-center gap-4 p-4'>
                <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className='rounded-lg border hover:border-gray-500 transform duration-300 bg-white text-black text-sm font-light py-1 px-4'>
                    Annuler
                </button>
                <button onClick={handleSubmit} type='button' className='rounded-lg bg-perso-buttonBlue text-black text-sm font-medium py-1 px-4'>
                    Valider
                </button>
            </div>
        </GenericModal>
    )
}
