/**
 * Validates the strength of a password based on the following criteria:
 * - Bon: Exactly 8 characters, including at least one uppercase letter, one lowercase letter, one digit, and one special character (!@#$%^&*_-+=~:;,.)
 * - Strong: More than 8 characters, including at least one uppercase letter, one lowercase letter, one digit, and one special character (!@#$%^&*_-+=~:;,.)
 * - Medium: At least 6 characters, including at least one uppercase letter, one lowercase letter, and one digit
 * - Weak: Does not meet the above criteria
 *
 * @param password - The password to validate
 * @returns The strength of the password ('bon', 'strong', 'medium', or 'weak')
 */
export const validatePassword = (password: string): string => {
    const strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_\-+=~:;,.?])[A-Za-z\d!@#$%^&*_\-+=~:;,.?]{9,}$/; // More than 8 characters
    const bonPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_\-+=~:;,.?])[A-Za-z\d!@#$%^&*_\-+=~:;,.?]{8}$/; // Exactly 8 characters
    const mediumPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*_\-+=~:;,.?]{6,}$/;

    if (strongPassword.test(password)) {
        return 'strong';
    } else if (bonPassword.test(password)) {
        return 'bon';
    } else if (mediumPassword.test(password)) {
        return 'medium';
    } else {
        return 'weak';
    }
};

/**
 * Validates if the password meets the following criteria:
 * - At least 8 characters
 * - At least one uppercase letter
 * - At least one lowercase letter
 * - At least one digit
 * - At least one special character (!@#$%^&*_-+=~:;,.?)
 *
 * @param password - The password to validate
 * @returns True if the password meets the criteria, false otherwise
 */
export const isPasswordValid = (password: string): boolean => {
    const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_\-+=~:;,.?])[A-Za-z\d!@#$%^&*_\-+=~:;,.?]{8,}$/;
    return passwordCriteria.test(password);
};

/**
 * Returns the color associated with the password strength.
 *
 * @param passwordStrength - The strength of the password ('strong', 'medium', 'bon', or 'weak')
 * @returns The color associated with the password strength
 */
export const getPasswordStrengthColor = (passwordStrength: string): string => {
    switch (passwordStrength) {
        case 'strong':
            return '#366F27'; // green
        case 'bon':
            return '#4CAF50'; // light green
        case 'medium':
            return '#FFC107'; // yellow
        case 'weak':
            return '#DC4949'; // red
        default:
            return '#E0E0E0'; // grey
    }
};

/**
 * Returns a score for the password based on its length and complexity.
 *
 * @param password - The password to score
 * @returns A score between 0 and 100
 */
export const getPasswordScore = (password: string): number => {
    let score = 0;
    if (password.length >= 8) score += 45;
    if (/[a-z]/.test(password)) score += 10;
    if (/[A-Z]/.test(password)) score += 20;
    if (/\d/.test(password)) score += 20;
    if (/[!@#$%^&*_\-+=~:;,.?]/.test(password)) score += 20;
    return score > 100 ? 100 : score;
};


/**
 * Checks if the password meets the specific criteria.
 *
 * @param password - The password to validate
 * @returns An object with the criteria and their respective boolean values
 */
export const checkPasswordCriteria = (password: string) => {
    return {
        length: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        digit: /\d/.test(password),
        specialChar: /[!@#$%^&*_\-+=~:;,.?]/.test(password),
    };
};