import React, {useEffect, useState} from "react";
import {User} from "../../../../types/types";
import {useAppContext} from "../../../../context/Context";
import InfoMessageNote from "../messageNote/InfoMessageNote";
import { TextAreaComponent } from '../../../../utils/FormComponents';
import useIntroductionMessage from './useIntroductionMessage';
import Maintenance from "../home/Maintenance";
import TitleAndSubTitle from "../TitleAndSubTitle";

export const IntroductionMessage: React.FC = () => {
    const [{ isAdminVirteem, user }, __] = useAppContext()
    const [{ firstText,defaultIntroductoryText }, { setfirstText, handleSubmit }] = useIntroductionMessage();
    return (
        <main className="flex flex-col gap-2">
            { user ? (
                <>     
                <TitleAndSubTitle h1="Gestion du message d'introduction" h2="Modifiez votre message d'introduction du chat de Virteem Companion"/>
            <br/>

           

            <hr/>
            <div className='mt-5'>

          <TextAreaComponent
            className='w-full'
            name='docContentIntro'
            value={firstText}
            onChange={(e) => setfirstText(e.target.value)}
            rows={4}
          />
          <div className='flex justify-end mt-2 gap-x-2'>
          <button
              onClick={() => setfirstText(defaultIntroductoryText)}
              type='button'
              className='rounded text-white text-sm font-medium py-2 px-4 bg-gray-500 hover:bg-gray-600'
            >
              Réinitialiser 
            </button>
            <button
              onClick={() => handleSubmit(user)}
              type='button'
              className='rounded text-white text-sm font-medium py-2 px-4 bg-gray-500 hover:bg-gray-600'
            >
              Modifier le message
            </button>
           
          </div>
          </div>
        </>
            ) : (
              <Maintenance/>
            )}
        </main>
    );
};


export default IntroductionMessage;