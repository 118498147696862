import { useEffect, useState } from "react"
import api from "../../utils/api"
import { setBottomBarError } from "../../utils/Functions"
import { useAppContext } from "../../context/Context"

export default function useManageFeedBacks() {
    const [{isAdminVirteem}, dispatch] = useAppContext();
    const [feedbacks, setFeedbacks] = useState([])

    useEffect(() => {
        loadFeedBacks()
    }, [])

    // Load feedback list and associate the company name if user is virteem admin
    const loadFeedBacks = async () => {
        try {
            // Fetch feedbacks list
            const feedbacksResponse = await api.get('/feedback/all');
            const feedbacksData = feedbacksResponse.data;
            if (isAdminVirteem) {
                // Fetch companies list
                const companiesResponse = await api.get('/company/all');
                const companiesData = await companiesResponse.data;
                // Create a map of companies by their id
                const companyMap = {};
                for (const company of companiesData) {
                    companyMap[company.id] = company;
                }
                // Associate company's name on each feedback
                for (const feedback of feedbacksData) {
                    feedback.company_name = companyMap[feedback.company_id].name;
                }
            }
            setFeedbacks(feedbacksData);
        }
        catch (error) {
            setBottomBarError(dispatch, error)
        }
    }



    return [{
        isAdminVirteem,
        feedbacks
    }, {
        loadFeedBacks,
        dispatch
    }]
}
