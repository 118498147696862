import GenericModal from '../../../utils/GenericModal'
import { ACTION } from '../../../context/actionTypes';
import { FaCheck } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';
import useManageCompanySales from '../../../hooks/companies/useManageCompanySales';

export default function ManageCompanySalesModal(props) {
    console.log("PROPS", props)
    const [{ sale, sales }, { setSale, dispatch, handleSubmit }] = useManageCompanySales(props);

    return (
        <GenericModal
            className="w-3/4 xl:w-2/3 overflow-hidden text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={false}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Associer un commercial</div>
            <div className=' overflow-y-auto max-h-96 p-4 grid grid-cols-2 gap-2'>
                {sales?.map((currentPlan, key) => (
                    <div
                        key={key}
                        onClick={() => setSale(currentPlan)}
                        className={` relative ${currentPlan.id === sale?.id ? ' border-2 border-green-300' : ' hover:border-green-300 cursor-pointer hover:shadow hover:-translate-y-1 '} p-4 border rounded  transform duration-300`}
                    >
                        <div className='font-bold text-xl'>{currentPlan.first_name}</div>
                        <div className=' font-light text-sm'>{currentPlan.last_name}</div>
                        <div className=''>Email : {currentPlan.email}</div>
                        <FaCheck className={twMerge(` ${currentPlan.id === sale?.id ? ' absolute ' : ' hidden '} bottom-0 right-0 bg-green-300 w-5 h-5 p-1 text-white`)} />
                    </div>
                ))}
            </div>
            <div className='flex justify-end gap-4 p-4'>
                <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className='rounded border bg-white text-black text-sm font-light py-2 px-4'>
                    Annuler
                </button>
                <button onClick={handleSubmit} type='button' className='rounded bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium py-2 px-4'>
                    Valider
                </button>
            </div>
        </GenericModal>
    )
}
