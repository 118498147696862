import React from 'react';
import './App.css';
import Router from './Router';
import ModalDisplayer from './components/ModalDisplayer';
import BottomBar from './utils/BottomBar';
import {Tooltip} from "react-tooltip";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router/>
        <ModalDisplayer/> {/* modal dialog boxes) depending on the current action specified in the application context */}
        <BottomBar /> {/* Bottom notification bar - hidden by default - display message when they are something to notice */}
        <Tooltip
          id="main-tooltip"
          style={{fontSize: "0.75rem", zIndex: 9999}}
          place='left'
        />
      </header>
    </div>
  );
}

export default App;
