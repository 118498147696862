import { useAppContext } from '../../../context/Context';
import api from '../../../utils/api';
import { setBottomBarError, setBottomBarSucces } from '../../../utils/Functions';
import { ACTION } from '../../../context/actionTypes';

export default function useDeleteFeedbacksModal(props:any) {
    const [_, dispatch] = useAppContext();
    const { id, reload } = props

    const deleteFeedbacks = async () => {
        try {
            await api.delete('/feedback/company')
            toggleMe()
            setBottomBarSucces(dispatch, "Les feedbacks ont été supprimés")
        }
        catch (error) {
            setBottomBarError(dispatch, error)
        }
    }

    const toggleMe = () => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });

    return [{}, { deleteFeedbacks, toggleMe }];
}
