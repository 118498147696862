import { useAppContext } from '../../context/Context'
import { getLinkFile } from './DisplayRagModal'
import MarkDownFormater from './MarkDownFormater'

export default function RagContentDisplay({ chunk, source, source_id, page, index = null }) {
    const [_, dispatch] = useAppContext()
    return (
        <div className='p-4 '>
            <div className='font-semibold w-full text-left  py-3 border-b text-sm'>
                <div className=' text-light flex gap-2 items-center justify-start'>
                    <div className='font-light'>Fichier :</div>
                    <div onClick={() => getLinkFile(source_id, dispatch)} className=' text-sm font-semibold hover:underline cursor-pointer'>{source}</div>
                </div>
                <div className=' font-light'><span className=''>Page :</span> <span className=' text-sm font-semibold'>{page}</span></div>
            </div>
            {/* <div onClick={() => getLinkFile(source_id, dispatch)} className='underline cursor-pointer'>Voir le fichier</div> */}
            <div className='text-sm pt-2 overflow-y-auto max-h-96'>
                <MarkDownFormater>
                {`[...] ${chunk} [...]`} 
                </MarkDownFormater>
            </div>
        </div>
    )
}
