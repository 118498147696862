import { useEffect, useState } from 'react';
import api from '../../utils/api';
import { useAppContext } from '../../context/Context';
import { ACTION } from '../../context/actionTypes';
import { setBottomBarError } from '../../utils/Functions';

export default function useManageCompanyOptions({ feedback_id, options, reload, id: company_id }) {
    const [optionsStatus, setOptionsStatus] = useState([]);
    const [_, dispatch] = useAppContext();

    useEffect(() => {
        const loadOptionsAndCompanyData = async () => {
            try {
                const optionsResponse = await api.get('/option/all');
                let finalOptionStatus = []
                optionsResponse.data.forEach(option => {
                    const found = options.find(x => x.option_id === option.id)
                    if (found) {
                        finalOptionStatus.push({ ...option, subscribed: true });
                    }
                    else {
                        finalOptionStatus.push({ ...option, subscribed: false });
                    }
                });
                setOptionsStatus(finalOptionStatus);
            } catch (error) {
                console.error("Failed to load options or company data", error);
                setBottomBarError(dispatch, error)
            }
        };
        loadOptionsAndCompanyData();
    }, [feedback_id]);

    const toggleOptionStatus = (optionId) => {
        setOptionsStatus(prevStatuses => prevStatuses.map(option =>
            option.id === optionId ? { ...option, subscribed: !option.subscribed } : option
        ));
    }

    const handleSubmit = async () => {
        const finalOptionsStatus = []
        optionsStatus.forEach(option => {
            if (option.subscribed && !options.find(x => x.option_id === option.id)) {
                finalOptionsStatus.push({
                    id: option.id,
                    subscribed: true,
                    starts_at: new Date().toISOString()
                })
            }
            else if(!option.subscribed && options.find(x => x.option_id === option.id)) {
                finalOptionsStatus.push({
                    id: option.id,
                    subscribed: false,
                    starts_at: new Date().toISOString()
                })
            }
        });
        try {
            await api.post(`/company/${company_id}/options`, { options: finalOptionsStatus })
            await reload()
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });
        } catch (error: any) {
            console.error("Failed to update options", error);
            setBottomBarError(dispatch, error);
        }
    }

    return [{
        optionsStatus
    }, {
        dispatch,
        toggleOptionStatus,
        handleSubmit,
    }];
}
