import React from 'react';

interface ToggleCheckboxProps {
  id: string;
  label?: string;
  checked: boolean;
  onChange: () => void;
}

const ToggleCheckbox: React.FC<ToggleCheckboxProps> = ({ id, label, checked, onChange }) => {
  return (
    <article className="flex flex-row gap-4 p-3 items-center">
      <input
        className="w-8 h-8 hover:text-virteem-lightBlue bg-gray-100 border-gray-300 rounded focus:text-virteem-lightBlue checked:text-virteem-lightBlue
                   dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:text-virteem-lightBlue"
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="w-full">
        {label ?? "Afficher le mot de passe"}
      </label>
    </article>
  );
};

export default ToggleCheckbox;
