import React from "react";
import "./ErrorMessageNote.css";

interface ErrorMessageNoteProps {
  display: any;
}

const ErrorMessageNote: React.FC<ErrorMessageNoteProps> = ({ display }) => {
     return (
        <div className="error-message" style={{color: '#DC4949'}}><p>{display}</p></div>
  );
}
export default ErrorMessageNote;