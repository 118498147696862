import React, { useEffect, useState } from 'react'
import api from '../../utils/api'
import { useAppContext } from '../../context/Context'
import { ACTION } from '../../context/actionTypes'
import { checkEmail, setBottomBarError } from '../../utils/Functions'

const initialCompany = {
    geographical_zone: '',
    name: '',
    email: '',
    sector: '',
    status: true,
}

export default function useCreateOrUpdateAdminModal(props) {
    const { first_name, last_name, email, status, id, company_id, is_admin, reload } = props
    const [user, setUser] = useState({ first_name, last_name, email, status, id, company_id, is_admin })
    const [companies, setCompanies] = useState([])
    const [_, dispatch] = useAppContext()
    const [errors, setErrors] = useState({} as any)
    const [company, setCompany] = useState(null)
    const [companyErrors, setErrorsCompany] = useState({} as any)

    const handleSubmit = async (e) => {
        e.preventDefault();
        let curErrors = {}
        let curErrorsCompany = {}
        if (!user.first_name) curErrors = { ...curErrors, first_name: "Le prénom est obligatoire" }
        if (!user.last_name) curErrors = { ...curErrors, last_name: "Le nom est obligatoire" }
        if (!checkEmail(user.email)) curErrors = { ...curErrors, email: "L'email est incorrecte" }
        if (!company && !user.company_id) curErrors = { ...curErrors, company_id: "Veuillez selectionner une entreprise" }
        if (company && !company.name) curErrorsCompany = { ...curErrorsCompany, name: "Le nom de l'entreprise est obligatoire" }
        if (company && !checkEmail(company.email)) curErrorsCompany = { ...curErrorsCompany, email: "L'email de l'entreprise est incorrecte" }
        if (company && !company.sector) curErrorsCompany = { ...curErrorsCompany, sector: "Le secteur de l'entreprise est obligatoire" }
        setErrors(curErrors)
        setErrorsCompany(curErrorsCompany)
        if (Object.keys(curErrors).length > 0 || Object.keys(curErrorsCompany).length > 0) return
        try {
            if (id) {
                await api.patch(`/user/admin/${id}`, user)
            }
            else if (company) {
                await api.post('/user/user_with_company', {
                    new_company: {
                        geographical_zone: "EU-WEST",
                        name: company.name,
                        email: company.email,
                        sector: company.sector,
                        status: company.status
                    },
                    new_user: {
                        is_admin: user.is_admin ? true : false,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email: user.email,
                        status: user.status
                    }
                })
            }
            else {
                await api.post(`/user/company/${user.company_id}`, {
                    company_id: user.company_id,
                    new_user: {
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email: user.email,
                        status: user.status,
                        company_id: user.company_id,
                        is_admin: user.is_admin ? true : false,
                    }
                })
            }
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })
            await reload()
        }
        catch (error) {
            console.log("ERROR", error)
            setBottomBarError(dispatch, error)
        }
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'company_id':
                setUser((old) => ({ ...old, [name]: value.id }))
                break
            default:
                setUser((old) => ({ ...old, [name]: value }))
                break
        }
    }
    const handleInputChangeCompany = (event) => {
        const { name, value } = event.target;
        setCompany((old) => ({ ...old, [name]: value }))
    }

    useEffect(() => {
        const init = async () => {
            try {
                const response = await api.get('/company/all')
                setCompanies(response.data)
            }
            catch (error) {
                setBottomBarError(dispatch, error)
            }
        }
        init()
    }, [])

    const initCompany = () => {
        setCompany(initialCompany)
    }
    const emptyCompany = () => {
        setCompany(null)
        setErrorsCompany({})
    }

    return [{
        companies,
        company,
        user,
        errors,
        companyErrors
    }, {
        dispatch,
        handleSubmit,
        handleInputChange,
        initCompany,
        emptyCompany,
        handleInputChangeCompany
    }]
}
