import { useEffect, useState } from 'react';
import api from '../../utils/api';
import { ACTION, GENERIC_MODAL_ACTIONS } from '../../context/actionTypes';
import { useAppContext } from '../../context/Context';
import { setBottomBarError } from '../../utils/Functions';

export default function useOptions() {
    const [_, dispatch] = useAppContext()
    const [options, setOptions] = useState([]);

    useEffect(() => {
        loadOptions();
    }, []);

    const loadOptions = async () => {
        try {
            const response = await api.get('/option/all');
            setOptions(response.data);
        } catch (error) {
            console.error("Failed to load options", error);
            setBottomBarError(dispatch, error)
        }
    };

    const createOption = async () => {
        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                props: { reload: loadOptions },
                action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_OPTION
            }
        })
    };

    return [{ options }, { loadOptions, createOption }];
}
