import { useState, useEffect } from 'react';

// Custom hook to determine device type
export const useDeviceType = () => {
    const [deviceType, setDeviceType] = useState('desktop');

    useEffect(() => {
        const checkDeviceType = () => {
            // Use window width to determine device type
            const threshold = 768; // Threshold in pixels to distinguish smartphones from laptops/desktops
            const width = window.innerWidth;

            if (width < threshold) {
                setDeviceType('smartphone');
            } else {
                setDeviceType('laptop/desktop');
            }
        };

        // Check once and also on every window resize
        checkDeviceType();
        window.addEventListener('resize', checkDeviceType);

        // Cleanup listener on component unmount
        return () => window.removeEventListener('resize', checkDeviceType);
    }, []); // Empty dependency array means this effect runs only once on mount

    return deviceType;
};
