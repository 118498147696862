// utils/axios.js
import axios from 'axios';
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  withCredentials: true,
});

// Request interceptor
api.interceptors.request.use(request => {
  // Always return the request otherwise it will be stuck here
  return request;
}, error => {
  // Log any request error
  console.error('Request error:', error);
  
  return Promise.reject(error);
});

export default api;
