import React from "react";
import "./SuccessMessageNote.css";
import {getPasswordScore, getPasswordStrengthColor, validatePassword} from "../pwd/pwdUtils";

interface ProgressBarProps {
  password : string;
}

const ProgressBarMessageNote: React.FC<ProgressBarProps> = ({ password }) => {
     const score = getPasswordScore(password);
    const strength = validatePassword(password);
    const width = `${score}%`;

    return (
        <div className="progress-bar-container">
            <div
                className="progress-bar"
                style={{
                    width: width,
                    backgroundColor: getPasswordStrengthColor(strength),
                }}
            >
            </div>
        </div>
    );
}
export default ProgressBarMessageNote;