import { useState } from 'react';
import api from '../../utils/api';
import { useAppContext } from '../../context/Context';
import { ACTION } from '../../context/actionTypes';
import { setBottomBarError, checkEmail } from '../../utils/Functions';

export default function useCreateOrUpdateSalesRepModal({ first_name, last_name, email, id, profile_picture, reload }) {
    const [salesRep, setSalesRep] = useState({ first_name, last_name, email, profile_picture, id });
    const [_, dispatch] = useAppContext();
    const [errors, setErrors] = useState({} as any);

    const handleInputChange = (event, isFile = false) => {
        if (isFile) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Assuming salesRep is an object containing all form fields
                    setSalesRep((prev) => ({ ...prev, profile_picture: reader.result }));
                };
                reader.readAsDataURL(file);
            }
        } else {
            const { name, value } = event.target;
            setSalesRep((old) => ({ ...old, [name]: value }));
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let curErrors = {};
        // TODO 
        // if (!salesRep.first_name) curErrors = { ...curErrors, first_name: "First name is required" };
        if (!salesRep.first_name) curErrors = { ...curErrors, first_name: "Le prénom est requis" };
        // TODO 
        // if (!salesRep.last_name) curErrors = { ...curErrors, last_name: "Last name is required" };
        if (!salesRep.last_name) curErrors = { ...curErrors, last_name: "Le nom est requis" };
        // TODO 
        // if (!checkEmail(salesRep.email)) curErrors = { ...curErrors, email: "Email is incorrect" };
        if (!checkEmail(salesRep.email)) curErrors = { ...curErrors, email: "Email est incorrect" };

        setErrors(curErrors);

        if (Object.keys(curErrors).length > 0) return;

        // Prepare the data for submission
        const submissionData = {
            first_name: salesRep.first_name,
            last_name: salesRep.last_name,
            email: salesRep.email,
            // Include profile_picture only if it's been added/changed
            ...(salesRep.profile_picture && { profile_picture: salesRep.profile_picture }),
        };

        try {
            if (id) {
                await api.patch(`/salesrep/${id}`, submissionData);
            } else {
                await api.post('/salesrep/', { new_salesrep: submissionData });
            }
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });
            await reload();
        } catch (error) {
            setBottomBarError(dispatch, error);
        }
    };


    return [{ salesRep, errors }, { dispatch, handleSubmit, handleInputChange }];
}
