import { AiFillWarning } from 'react-icons/ai'
import GenericModal from './GenericModal'

export default function ConfirmModal({ text, yes, no, yesLabel = "Continuer", noLabel = "Annuler" }) {
    return (
        <div>
            <GenericModal
                className="w-full lg:w-2/5 xl:w-1/3 2xl:w-1/4 overflow-hidden text-base font-light text-black bg-white rounded-lg"
                showCloseButton={true}
                exitClick={true}
                blurBackGround={true}
                toggleMe={() => no()}
            //   className="w-5/6 sm:w-1/3 text-base text-black"
            >
                <div className="p-8">
                    <AiFillWarning className="mx-auto w-1/6 text-red-500 rounded-t-xl h-auto" />
                    <div className=" py-4 whitespace-pre-wrap ">{text}</div>
                    <div className="flex w-full lg:w-1/2 mx-auto pt-2 items-center gap-4">
                        <button onClick={no} className="text-white transition-all transform duration-500 hover:-translate-y-0.5 hover:shadow-lg hover:opacity-100 opacity-90 text-sm p-3 rounded-lg bg-gradient-to-br from-gray-400 to-gray-500 shadow w-1/2 text-center font-bold">{noLabel}</button>
                        <button onClick={yes} className="text-black transition-all transform duration-500 hover:-translate-y-0.5 hover:shadow-lg hover:opacity-100 opacity-90 text-sm p-3 rounded-lg bg-gradient-to-br from-blue-300 to-blue-500 shadow w-1/2 text-center font-bold">{yesLabel}</button>
                    </div>
                </div>
            </GenericModal>
        </div>
    )
}
