import React, { useState, useRef, useEffect, FunctionComponent, HTMLAttributes } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { HiOutlineClipboardCopy } from "react-icons/hi";

type Renderable = string | number | React.ReactNode;

const renderToString = (children: Renderable): string => {
    if (typeof children === 'string' || typeof children === 'number') {
        return children.toString();
    } else if (Array.isArray(children)) {
        return children.map(child => renderToString(child)).join('');
    } else if (React.isValidElement(children) && children.props) {
        return renderToString(children.props.children);
    }
    return '';
};

interface CodeBlockProps extends HTMLAttributes<HTMLElement> {
    node?: any;
    inline?: boolean;  // Make inline optional
}

const CodeBlock: FunctionComponent<CodeBlockProps> = ({ node, inline = false, className, children, ...props }) => {
    const [isCopied, setIsCopied] = useState(false);
    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState('auto');

    useEffect(() => {
        if (containerRef.current) {
            setContainerHeight(`${containerRef.current.getBoundingClientRect().height}px`);
        }
    }, [children]);

    const match = /language-(\w+)/.exec(className || '');
    const codeString = renderToString(children);

    const handleCopy = async () => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(codeString);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 4000);
        }
    };

    return match ? (
        <div style={{ position: 'relative', height: containerHeight }} className="mt-12 ">
            <div ref={containerRef} className='absolute -top-2 left-0 w-full '>
                <SyntaxHighlighter style={vscDarkPlus} language={match[1]} PreTag="div" {...props}>
                    {codeString}
                </SyntaxHighlighter>
            </div>
            <div className='absolute -top-8 left-0 w-full bg-perso-darkBlue rounded-t-lg p-2 flex items-center text-gray-200'>
                <div className="flex-grow text-xs">{match[1]}</div>
                <button className="flex gap-2 items-center text-xs" onClick={handleCopy}>
                    {isCopied ? 'Code copied!' : <HiOutlineClipboardCopy className="w-4 h-4" />}
                </button>
            </div>
        </div>
    ) : null;
};

export default CodeBlock;

