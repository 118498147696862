import React from 'react'
import Chat from './chat/Chat'

export default function Main() {
  return (
    <div className='w-full'>
      <Chat />
    </div>
  )
}
