import {checkPasswordCriteria} from "./pwdUtils";
import React from "react";


interface PasswordCriteriaCheckerProps {
    password: string;
}

const PasswordCriteriaChecker: React.FC<PasswordCriteriaCheckerProps> = ({ password }) => {
    const criteria = checkPasswordCriteria(password);

    return (
        <div className="password-criteria-checker mt-4">
            <div className="flex flex-row">
                <span className={`mr-2 ${criteria.length ? 'text-green-500' : 'text-red-500'}`}>
                    {criteria.length ? '✓' : '✗'}
                </span>
                <span>Au moins 8 caractères</span>
            </div>
            <div className="flex flex-row">
                <span className={`mr-2 ${criteria.uppercase ? 'text-green-500' : 'text-red-500'}`}>
                    {criteria.uppercase ? '✓' : '✗'}
                </span>
                <span>
                    Au moins une lettre majuscule
                </span>

            </div>
            <div className="flex flex-row">
                <span className={`mr-2 ${criteria.lowercase ? 'text-green-500' : 'text-red-500'}`}>
                    {criteria.lowercase ? '✓' : '✗'}
                </span>
                <span>
                    Au moins une lettre minuscule
                </span>
            </div>
            <div className="flex flex-row">
                <span className={`mr-2 ${criteria.digit ? 'text-green-500' : 'text-red-500'}`}>
                    {criteria.digit ? '✓' : '✗'}
                </span>
                <span>
                Au moins un chiffre
                </span>

            </div>
            <div className="flex flex-row">
                <span className={`mr-2 ${criteria.specialChar ? 'text-green-500' : 'text-red-500'}`}>
                    {criteria.specialChar ? '✓' : '✗'}
                </span>
                <span>
                    Au moins un des caractères spéciaux suivants : !@#$%^&*_-+=~ : ;,. ?
                </span>
            </div>
        </div>
    );
};

export default PasswordCriteriaChecker;
