import {ApiKeySchema} from "./ApiKeys";
import {useAppContext} from "../../../../context/Context";
import {RemoveAPIKeyCallback} from "./DeleteApiKeyModal";
import {ACTION, GENERIC_MODAL_ACTIONS} from "../../../../context/actionTypes";
import {setBottomBarSucces} from "../../../../utils/Functions";
import {AddOrUpdateAPIKeyCallback} from "./useCreateOrUpdateApiKey";
import {FaEdit} from "react-icons/fa";
import {GoCopy} from "react-icons/go";
import FigmaBinRed from "../../../../utils/icons/FigmaBinRed";
import {AiFillWarning} from "react-icons/ai";


interface ApiKeyDisplayProps {
    apiKey: ApiKeySchema;
    removeApiKey: RemoveAPIKeyCallback;
    addOrUpdateApiKey: AddOrUpdateAPIKeyCallback;
}

export default function ApiKeyDisplay(props: ApiKeyDisplayProps) {
    const [, dispatch] = useAppContext();
    const {apiKey: {name, token}} = props;

    return <div className="api-key p-4 border-solid border border-figmaColors-subBorder rounded-xl flex flex-col gap-4">
        <div className="flex flex-row justify-between">
            <div>
                <div className="text-xl font-semibold text-figmaColors-mainText">{name}</div>
                <div className="text-sm text-figmaColors-mainText opacity-100">Cette clé n'a pas de date d'expiration</div>
            </div>
            <div className="flex flex-row gap-4 items-center">
                <FaEdit
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content="Modifier"
                    className="w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none"
                    onClick={() => {
                        dispatch({
                            type: ACTION.SET_GENERIC_MODAL,

                            payload: {
                                props: props,
                                action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_API_KEY
                            }
                        })
                    }}
                />

                <FigmaBinRed
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content="Supprimer"
                    className="w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none"
                    onClick={() => {
                        dispatch({
                            type: ACTION.SET_GENERIC_MODAL,

                            payload: {
                                props: props,
                                action: GENERIC_MODAL_ACTIONS.DELETE_API_KEY
                            }
                        });
                    }}
                />
            </div>
        </div>
        {token && <div className="text-base p-1 flex flex-col gap-4 w-fit self-center">
            <div className="flex flex-row gap-2 p-4 rounded break-all border border-figmaColors-ActiveBlueInteraction text-figmaColors-ActiveBlueInteraction">
                <div className="text-xs font-semibold">{token}</div>
                <GoCopy
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content="Copier"
                    className="w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none"
                    onClick={() => {
                        navigator.clipboard.writeText(token);
                        setBottomBarSucces(dispatch, "Le token a été copié dans votre presse papier");
                    }}
                />
            </div>

            <div className="text-sm mt-1 text-figmaColors-warningFeedback flex flex-row items-center gap-2">
                <AiFillWarning className="h-5 w-5"/>
                <div>
                    Attention, ce token ne vous sera plus montré. Copiez le dans un endroit sécurisé !
                </div>
            </div>
        </div>}
    </div>
}