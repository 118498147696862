import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { FaSignInAlt } from 'react-icons/fa';
import { useAppContext } from '../../context/Context';
import { Link } from 'react-router-dom';
import { LuFileSpreadsheet } from "react-icons/lu";
import { VscFeedback } from "react-icons/vsc";
import { GrContactInfo } from "react-icons/gr";
import { PiUserSwitch } from "react-icons/pi";
import { GiChoice, GiSettingsKnobs } from "react-icons/gi";
import { MdHomeFilled, MdOutlineCorporateFare } from "react-icons/md";
import { CgOptions } from "react-icons/cg";
import {IoIosSettings} from "react-icons/io";

interface LinkType {
    label: string;
    href: string;
    icon: JSX.Element;
    selectionStartsWith?: boolean
}

/** Group all the links shared by the virteem admin, the admin client and the user
 *
 */
const initLinks = [
    // { label: "Dashboard", href: `/administration/`, icon: <MdHomeFilled className="w-6 h-6" /> },
    // { label: "Documents", href: `/administration/documents`, icon: <LuFileSpreadsheet className="w-6 h-6" /> },
    // { label: "Clients", href: `/administration/clients`, icon: <FaUsers className="w-5 h-5" /> },
    // { label: "Retours", href: `/administration/feedbacks`, icon: <VscFeedback className="w-6 h-6" /> },
    // { label: "Tutos", href: `/administration/tutos`, icon: <IoSchoolOutline className="w-5 h-5" /> }
]

/** Group all the links virteem admin
 *
 */
const adminLinks = [
    { label: "Gestion Plan", href: `/administration/admin-plan`, icon: <CgOptions className="w-6 h-6" /> },
    { label: "Référent", href: `/administration/admin-commercial-virteem`, icon: <GrContactInfo className="w-6 h-6" /> },
    { label: "Gestion Utilisateur", href: `/administration/admin-users`, icon: <PiUserSwitch className="w-6 h-6" /> },
    { label: "Gestion Entreprises", href: `/administration/admin-companies`, icon: <MdOutlineCorporateFare className="w-6 h-6" /> },
    { label: "Gestion Options", href: `/administration/admin-options`, icon: <GiChoice className="w-6 h-6" /> },
    { label: "Gestion Technique", href: `/administration/admin-technical`, icon: <GiSettingsKnobs className="w-6 h-6" /> , selectionStartsWith: true },

]


/** Group all the links shared by the virteem admin and the admin client 
 *
 */
const adminClientLinks = [
    { label: "Dashboard", href: `/administration/`, icon: <MdHomeFilled className="w-6 h-6" /> },
    { label: "Documents", href: `/administration/documents`, icon: <LuFileSpreadsheet className="w-6 h-6" /> },
    { label: "Retours", href: `/administration/feedbacks`, icon: <VscFeedback className="w-6 h-6" /> },
]


/** Burger menu display (nav bar) on the left of the screen that group all the link that the user can used
 * by default these links are displayed :
 *      - Dashboard
 *      - Document
 *      - Feedback
 *      - ChatBot
 *      - Log out
 * According to the isAdminVirteem, some links are displayed or not
 * if the user is isAdminVirteem then display additionnally:
 *      - admin plan
 *      - salesrep
 *      - user manager (admin users)
 *      - company manager
 *      - option manager
 *
 *
 * @param logOut
 * @param company_uuid
 * @constructor
 */
export default function Menu({ logOut, company_uuid }) {
    const [links, setLinks] = useState<LinkType[]>([])
    const location = useLocation();
    const [{ user, isLoggedIn, isAdminVirteem, isAdmin }, __] = useAppContext()

    useEffect(() => {
        if (isAdminVirteem) {
            setLinks([...adminClientLinks, ...adminLinks])
        }
        else if (isAdmin){
            setLinks(adminClientLinks)

        }
        else {
            setLinks(initLinks)
        }
    }, [user])

    return (
        <div className="flex flex-col justify-between h-full">
            <div className='flex flex-col justify-start h-ful'>
                {links?.map((link, key) => (
                    <div data-tip={link.label} key={key}>
                        <Link
                            to={link.href}
                        >
                            <div className={` 
                                    flex items-center justify-start pl-8 gap-2 p-4 transition-all duration-300 transform
                                    ${(link.selectionStartsWith ? location.pathname.startsWith(link.href) : location.pathname === link.href) ? " bg-perso-blue border-l-4 border-perso-lightBlue  text-white " : " hover:bg-opacity-25 hover:bg-blue-300 "}
                                    `}>
                                <div>{link.icon}</div>
                                <div className={` text-left w-full text-sm font-bold `}>
                                    {link.label}
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
                <div className=' hover:bg-opacity-25 hover:bg-blue-300'>
                    <div className=' border-t w-3/4 ml-8'/>
                    <Link
                        to={`${window.location.origin}/${company_uuid}`}
                        target='_blank'
                        className='flex w-full items-center justify-start pl-8 gap-2 p-4 transition-all duration-300 transform'
                    >
                        <img src="/chatbot.svg" alt="chatbot" className="w-6 h-6"/>
                        <div>Chatbot</div>
                    </Link>
                </div>
            </div>

            {isLoggedIn && (
                <div className="flex flex-col justify-end w-full">
                    <Link
                        to={`/administration/settings`}
                        // target='_blank'
                        className={` 
                                    flex items-center justify-start pl-8 gap-2 p-4 transition-all duration-300 transform
                                    ${location.pathname.startsWith("/administration/settings/") ? "bg-perso-blue border-l-4 border-perso-lightBlue  text-white" : "hover:bg-opacity-25 hover:bg-blue-300"}
                                    `}
                        >
                            <IoIosSettings className="w-5 h-5"/>
                            <div>Paramètres</div>
                        </Link>
                        <button
                            className="flex w-full items-center justify-start pl-8 gap-2 p-4 transition-all duration-300
                        transform hover:bg-opacity-25 hover:bg-blue-300 "
                            onClick={logOut}
                        >
                            <FaSignInAlt className="w-5 h-5"/>
                            <div>Me déconnecter</div>
                        </button>

                    </div>
                )}
        </div>

    )
}
