import React from 'react'
import { FiX } from 'react-icons/fi'
import { twMerge } from 'tailwind-merge';

/**
 * Props for the GenericModal component.
 *
 * @param children - The content of the modal.
 * @param toggleMe - The function to toggle the modal.
 * @param className - (Optional) The className to be added to the modal.
 * @param exitClick - (Optional) A boolean to indicate if the modal should close when the user clicks outside of it.
 * @param showCloseButton - (Optional) A boolean to indicate if the modal should show a close button.
 */
interface GenericModalProps {
    children: React.ReactNode;
    toggleMe?: () => void;
    className?: string;
    exitClick?: boolean;
    showCloseButton?: boolean;
    blurBackGround?: boolean;
}
export default function GenericModal(props: GenericModalProps) {
    const { children, toggleMe = null, className, exitClick = false, showCloseButton = false, blurBackGround = false } = props

    return (
        <div
            onClick={() => exitClick && toggleMe()}
            className={twMerge(`justify-center h-screen w-screen items-center flex fixed inset-0 z-[3000] focus:outline-none bg-black bg-opacity-20 overflow-y-scroll ${blurBackGround && ' backdrop-blur-sm '}`)}
        >
            <div className={twMerge(`relative top-0 max-h-3/4 overflow-hidden border-0 rounded-lg shadow-lg flex flex-col bg-white outline-none focus:outline-none mx-4 ${className}`)}>
                <div onClick={() => toggleMe()}>
                    {showCloseButton && (
                        <button
                            className="absolute top-2 right-2 p-1 text-gray-600 z-40 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => toggleMe()}
                        >
                            <div className="flex items-center justify-items-center bg-white rounded-full">
                                <FiX className="h-4 w-4" />
                            </div>
                        </button>
                    )}
                    <div onClick={(e) => e.stopPropagation()}>
                        {children}
                    </div>
                </div>
            </div>
        </div>

    )
}