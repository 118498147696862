import React from 'react';
import './progressBar.css';
import './ChangePassword.css';

import backgroundImage from "../../../chat/background.svg";
import ResetPassword from "./ResetPassword";
import {Step} from "./Step";


/** Main component for changing the password
 *
 * @constructor
 */
const Recovery: React.FC = () => {
    return (
        /** Return form :
         * Contains fields for email, current password, new password and new password confirmation.
         * Displays password strength in real time.
         * Displays errors if present.
         * Contains a submit button to change password.
         *
         */
        <main className='w-full h-screen pt-12 p-100 flex flex-col items-center' style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}>

            <div className="flex-col bg-white rounded-lg w-1/3">
                <ResetPassword finalStep={Step.Login} title="Récupérer votre compte"/>
            </div>

        </main>
    );
}
export default Recovery;
