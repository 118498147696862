import React from 'react'
import api from '../utils/api'
import { useAppContext } from '../context/Context'
import { ACTION } from '../context/actionTypes'
import { setBottomBarError } from '../utils/Functions'

export default function useDeleteAdmin(props: any) {
    const [_, dispatch] = useAppContext()
    const { id, reload } = props
    const deleteAdmin = async () => {
        try {
            await api.delete(`/user/admin/${id}`)
            await reload()
            toggleMe()
        }
        catch (err) {
            setBottomBarError(dispatch, err)
        }
    }

    const toggleMe = () => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })

    return [{}, { deleteAdmin, toggleMe }]
}
