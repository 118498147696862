import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    maintainAspectRatio : false,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
    scales: {
        y: {
            beginAtZero: true,
            ticks: {
                stepSize: 1,
                precision: 0,
            },
            grid: {
                display: true,
            },
        },
        x: {
            grid: {
                display: false,
            },
            ticks: {
                display: false,
            }
        },
    },
    datasets: {
        bar: {
            barThickness: 10,
            maxBarThickness: 15
        }
    }
};

export const CustomBarChart = ({ data }) => {
    const labels = ['No Grade', 'Good Grade', 'Medium Grade', 'Bad Grade',];
    const chartData = {
        labels,
        datasets: [
            {
                data: [data.noGrade, data.goodGrade, data.mediumGrade, data.badGrade,],
                backgroundColor: ['rgba(236,239,243,255)', 'rgba(112,198,131,255)', 'rgba(246,174,107,255))', 'rgba(243,107,108,255)'],
                borderColor: ['rgba(236,239,243,255)', 'rgba(112,198,131,255)', 'rgba(246,174,107,255)', 'rgba(243,107,108,255)'],
                borderWidth: 1,
            }
        ],
    };

    return data && <Bar options={options} data={chartData} />;
};
