import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeHighlight from 'rehype-highlight'
import CodeBlock from './CodeBlock'
import { useEffect, useRef, useState } from 'react'
import styles from './MarkDownFormatter.module.css';

function renderLink(props) {
    return <a href={props.href} target="_blank" rel="noopener noreferrer" {...props} />
}

export default function MarkDownFormater({ children }) {



    const containerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState('auto');

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setContainerHeight(`${containerRef.current.getBoundingClientRect().height}px`);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Clean up function
        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);

    return (
        <div className={`relative w-full ${styles.markdownFormatter}`}>
            <div ref={containerRef} className="absolute">
                <ReactMarkdown
                    className={`text-sm sm:text-base ${styles.markdownFormatter}`}
                    rehypePlugins={[rehypeHighlight]}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        code: CodeBlock,
                        ol: ({ node, ...props }) => <ol style={{ listStyleType: 'decimal', marginLeft: '20px' }} {...props} />,
                        ul: ({ node, ...props }) => <ul style={{ listStyleType: 'disc', marginLeft: '20px' }} {...props} />,
                        li: ({ node, ...props }) => <li {...props} />,
                        a: renderLink,
                    }}
                >
                    {children}
                </ReactMarkdown>
            </div>
            <div style={{ height: containerHeight }} className="" />
        </div>
    )
}
