import GenericModal from "../../../../utils/GenericModal";
import {InputComponent, SwitchComponent} from "../../../../utils/FormComponents";
import useCreateOrUpdateApiKey, {UseCreateApiKeyProps} from "./useCreateOrUpdateApiKey";
import React from "react";


export default function CreateOrUpdateApiKeyModal(props: UseCreateApiKeyProps) {
    const [{
        apiKey,
        errors,
    }, {
        toggleMe,
        handleInputChange,
        handleSubmit
    }] = useCreateOrUpdateApiKey(props);

    return (
        <GenericModal
            className="w-3/4 lg:w-2/5 xl:w-1/2 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={false}
            toggleMe={toggleMe}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>{apiKey.id ? "Modifier une clé d'API" : "Ajouter une clé d'API"}</div>
            <form onSubmit={handleSubmit} noValidate>
                <div className='flex flex-col gap-4 p-8'>
                    <InputComponent
                        label="Nom:"
                        name="name"
                        value={apiKey.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                    {apiKey.id && (
                        <SwitchComponent
                            label="Régénérer le token"
                            name="regenerate"
                            checked={apiKey.regenerate}
                            onChange={handleInputChange}
                            checkedLabel="Oui"
                            uncheckedLabel="Non"
                            className='col-span-2 xl:col-span-1'
                        />
                    )}
                </div>
                <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                    <button
                        onClick={toggleMe}
                        className='rounded border bg-white text-black text-sm font-light py-1 px-2'
                    >Annuler</button>
                    <button
                        type='submit'
                        className='rounded bg-gray-600 hover:bg-gray-700 hover:shadow text-white text-sm py-1 px-2 transform duration-300'
                    >Valider</button>
                </div>
            </form>
        </GenericModal>
    );
}
