import { useAppContext } from "../../context/Context";
import { ACTION } from "../../context/actionTypes";
import { setBottomBarError } from "../../utils/Functions";
import api from "../../utils/api";

export default function useDeletePlan(props) {
    const [_, dispatch] = useAppContext();
    const { id, reload } = props;

    const deletePlan = async () => {
        try {
            await api.delete(`/plan/${id}`);
            await reload();
            toggleMe();
        } catch (err) {
            console.error("Failed to delete plan", err);
            setBottomBarError(dispatch, err);
        }
    };

    const toggleMe = () => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });

    return [{}, { deletePlan, toggleMe }];
}
