import React from "react";

export const Maintenance: React.FC = () => {
    return (
        <main>
            <h1>Bienvenue !</h1>
            <br/>
            <p>Maintenance du système en cours... Veuillez ré-essayez ultérieurement.</p>
            <p>En vous remerciant de votre compréhension,</p>
            <br/>
            <p>L'équipe technique de Virteem Companion.</p>
        </main>
    );
};


export default Maintenance;