import RagContentDisplay from "./RagContentDisplay";

export default function SearchModeDisplayer({ searchResponse, isLoading }) {
    return (
        <div className="relative text-base text-black py-12 lg:py-8 w-full lg:w-3/4 mx-auto">
            {/* Si isLoading est vrai, affiche l'icône de chargement */}
            {isLoading ? (
                <div className="flex items-start gap-2 pb-3">
                    {/* Conteneur de l'icône de chargement, aligné à gauche */}
                    <div className="w-6 h-6 pt-3">
                        <img src="/chatbot-black.svg" alt="loading" className="w-full flex-shrink-0" />
                    </div>
                    {/* Animation de chargement avec texte en noir */}
                    <div className="bg-gray-500 p-2 rounded-lg flex gap-1 text-xl animate-pulse text-black">
                        <div className="">•</div>
                        <div className="">•</div>
                        <div className="">•</div>
                    </div>
                </div>
            ) : (
                // Si isLoading est faux, affiche le contenu de la réponse
                <div className="space-y-4 text-base w-full">
                    {/* Affiche le nombre de fichiers, ou 0 si aucun résultat */}
                    <div className="font-bold pb-2">
                        {searchResponse?.rag?.length > 0
                            ? `${searchResponse.rag.length} Fichier(s)`
                            : "0 Fichier"}
                    </div>
                    {/* Affiche chaque résultat sous forme de carte */}
                    {searchResponse?.rag?.map((rag, key) => (
                        <div
                            key={key}
                            className="bg-white p-4 font-light rounded-r-xl rounded-bl-xl shadow-lg transform duration-500 w-full"
                        >
                            <RagContentDisplay
                                chunk={rag.chunk}
                                source={rag.source}
                                source_id={rag.source_id}
                                page={rag.page}
                                index={key}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
