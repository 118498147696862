import React, { useState } from 'react';
import api from '../../utils/api';
import { useAppContext } from '../../context/Context';
import { ACTION } from '../../context/actionTypes';
import { checkEmail, setBottomBarError } from '../../utils/Functions';

export default function useCreateOrUpdateCompany({ name, email, sector, geographical_zone, status, id, reload }) {
    const [company, setCompany] = useState({ name, email, sector, geographical_zone, status, id });
    const [errors, setErrors] = useState({} as any);
    const [_, dispatch] = useAppContext();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCompany(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let curErrors = {};
        if (!company.name) curErrors = { ...curErrors, name: "Le nom de l'entreprise est obligatoire" }
        if (!checkEmail(company.email)) curErrors = { ...curErrors, email: "L'email de l'entreprise est incorrecte" }
        if (!company.sector) curErrors = { ...curErrors, sector: "Le secteur de l'entreprise est obligatoire" }

        setErrors(curErrors);

        if (Object.keys(curErrors).length > 0) return;

        try {
            if (id) {
                await api.patch(`/company/${id}`, { updated_company: company });
            } else {
                await api.post('/company/', { new_company: company });
            }
            dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });
            reload()
        } catch (error) {
            console.error("Failed to create/update company", error);
            // TODO english version 
            //setErrors({ submit: "Failed to submit company data." });
            setErrors({ submit: "Impossible de soumettre les données de l’entreprise." });
            setBottomBarError(dispatch, error)
        }
    };

    return [{
        company,
        errors,
    }, {
        dispatch,
        setCompany,
        handleInputChange,
        handleSubmit
    }];
}
