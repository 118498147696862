import TitleAndSubTitle from "../TitleAndSubTitle";
import React, {useEffect} from "react";
import {useAppContext} from "../../../../context/Context";
import api from "../../../../utils/api";
import {setBottomBarError} from "../../../../utils/Functions";
import ApiKeyDisplay from "./ApiKeyDisplay";
import {ACTION, GENERIC_MODAL_ACTIONS} from "../../../../context/actionTypes";
import "./ApiKeys.css";
import {IoAddCircleOutline} from "react-icons/io5";

export interface ApiKeySchema {
    id: number;
    name: string;
    token?: string;
}

export default function ApiKeys() {
    const [{user}, dispatch] = useAppContext()
    const [apiKeys, setApiKeys] = React.useState<ApiKeySchema[]>([]);

    useEffect(() => {
        async function fetchAPIKeys() {
            try {
                const response = await api.get(`/company/${user.company_id}/api-keys`);
                setApiKeys(response.data);
            } catch (e) {
                setBottomBarError(dispatch, e)
            }
        }

        if (user) fetchAPIKeys()
    }, [dispatch, user]);


    // Called by the CreateOrUpdateApiKeyModal when the API key is created or updated
    function handleAddOrUpdateApiKey(newApiKey: ApiKeySchema) {
        setApiKeys((prev) => {
            const foundIndex = prev.findIndex((apiKey: ApiKeySchema) => apiKey.id === newApiKey.id);
            if (foundIndex < 0) {
                return [...prev, newApiKey];
            }
            prev[foundIndex] = newApiKey;
            return prev;
        });
    }

    // Called by the DeleteApiKeyModal when the API key is deleted
    function handleRemoveApiKey(apiKeyId: number) {
        setApiKeys((prev) => prev.filter((apiKey) => apiKey.id !== apiKeyId));
    }

    return <main className="flex flex-col gap-2">
        <TitleAndSubTitle
            h1="Clés d'API"
            h2="Créez une clé d'API vous permettant d'intéragir avec les API publiques de Virteem Companion."
        />

        <div className="flex flex-col gap-2 api-key-list">
            {apiKeys.map((apiKey) => (
                <ApiKeyDisplay
                    key={apiKey.id}
                    apiKey={apiKey}
                    removeApiKey={handleRemoveApiKey}
                    addOrUpdateApiKey={handleAddOrUpdateApiKey}
                />
            ))}
        </div>

        <button
            className="btn-transition-l bg-perso-blue hover:bg-opacity-75 w-fit mt-2 flex flex-row items-center gap-2"
            onClick={() => {
                dispatch({
                    type: ACTION.SET_GENERIC_MODAL,

                    payload: {
                        props: {addOrUpdateApiKey: handleAddOrUpdateApiKey},
                        action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_API_KEY
                    }
                });
            }}
        >
            <IoAddCircleOutline className="w-5 h-5"/>
            <div>Créer une clé d'API</div>
        </button>
    </main>
}