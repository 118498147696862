interface ActionContainerProps {
    text: string;
    children: React.ReactNode;
}

export default function ActionContainer({text, children}: ActionContainerProps) {
    return <div className="flex flex-row gap-4 items-center">
        {children}
        <p>{text}</p>
    </div>
}