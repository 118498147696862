import {useState} from "react";
import TitleAndSubTitle from "../TitleAndSubTitle";
import Card from "./Card";
import "./Changelog.css";
import {SlArrowDown, SlArrowUp} from "react-icons/sl";

export default function Changelog() {
    const [selectedChangelog, setSelectedChangelog] = useState(null);

    return <div className="changelog">
        {/*Incrémenter le nombre dans les conditions chaque fois qu'on ajoute un changelog pour que le expand/collapse fonctionne*/}


        <div onClick={() => setSelectedChangelog(selectedChangelog === 1 ? null : 1)} className="cursor-pointer">
            <div className="flex flex-row items-baseline gap-2">
                {selectedChangelog === 1 ? <SlArrowUp/> : <SlArrowDown/>}
                <TitleAndSubTitle h1="Version v1.2.1"
                                  h2="Cette mise à jour améliore l'expérience utilisateur, la gestion des documents, et la précision des résultats de recherche, tout en optimisant les performances de l'application."/>
            </div>
            {selectedChangelog === 1 && (
                <>
                    <Card title="Corrections de bugs :" parts={[
                        "Tableau de bord : Les dates sélectionnables ont maintenant des limites pour éviter des erreurs. Cela empêche de choisir des dates non valides et améliore les performances globales.",
                        "Fenêtres pop-up (Modales) : Les fenêtres ne se fermeront plus si vous cliquez accidentellement en dehors, ce qui prévient la perte de données non enregistrées. Cela concerne les avis (feedbacks), les plans, les utilisateurs, etc.",
                        "Cohérence des couleurs : Les couleurs du tableau de bord et des documents respectent maintenant la charte graphique de l'application, ce qui renforce l'image de marque et améliore l'accessibilité.",
                        "Uniformisation des polices : Les polices de caractères sont désormais cohérentes sur toutes les pages, renforçant l'apparence professionnelle et l'identité de l'application.",
                        "Recherche (RAG) : Les résultats de recherche sont maintenant plus précis. Il n'y aura plus d'extraits de documents non pertinents dans les réponses fournies par le système, garantissant que les documents retournés sont toujours en lien avec la question posée."
                    ]}/>
                    <Card title="Améliorations :" parts={[
                        "Suivi des avis : Des pastilles de couleur ont été ajoutées pour indiquer si un avis est en cours ou traité, ce qui facilite le suivi.",
                        "Documents : Les messages de confirmation lors de la suppression de documents ont été améliorés pour plus de clarté."
                    ]}/>
                    <Card title="Nouvelles fonctionnalités :" parts={[
                        <Card title="Tableau de bord (Dashboard) :" parts={[
                            "Vous pouvez désormais sélectionner des plages de dates pour mieux voir les évolutions des requêtes, des avis laissés par les utilisateurs.",
                            "Un compteur affiche en pourcentage l'utilisation des requêtes par rapport au quota prévu par le plan de l'entreprise.",
                            "Un autre compteur montre l'espace utilisé par les documents de l'entreprise."
                        ]}/>,
                        "Documents : Il est maintenant possible de sélectionner un ou plusieurs documents à la fois."
                    ]}/>
                </>
            )}
        </div>
    </div>
}