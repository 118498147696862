import { useEffect, useState } from "react";


import {useAppContext} from "../../../../context/Context";

export default function useAffichage() {
    const [_, dispatch] = useAppContext();
    const [percentages, setpercentages] = useState(false)
    const getshowPercentages=()=>{
        const stored = localStorage.getItem("showPercentages");
        if(stored=="true")
            setpercentages(true)
        else
            setpercentages(false)
    }
    const setshowPercentages=(e)=>{
         localStorage.setItem("showPercentages",e.target.checked?"true":"false");
         setpercentages(e.target.checked)
    }
    useEffect(() => {
     getshowPercentages()
    }, [])
    
    return   [{percentages},{
        setshowPercentages
    }]
}
