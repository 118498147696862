import { FaArrowRight } from 'react-icons/fa'
import { CHAT_MODE } from '../../context/actionTypes'
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'
import { SwitchComponent } from '../../utils/FormComponents'

export default function ContentMenu({ withHistory, toggleHistory, hasLLMChatPureOption, mode, setMode }) {
    return (
        <div className='p-8 space-y-8'>

            <div className=' space-y-2'>
                <div className='font-bold flex items-center gap-1 text-lg'>{'Mode'}</div>
                <div className="text-xs space-y-2">
                    <div
                        onClick={() => setMode(CHAT_MODE.QUERY)}
                        className={twMerge(`rounded-lg p-2 font-bold flex items-center gap-1 cursor-pointer transform duration-300 border ${mode === CHAT_MODE.QUERY ? " bg-perso-lightBlue border-perso-lightBlue text-black" : "  bg-transparent border-white text-white"}`)}
                        data-tooltip-id="main-tooltip"
                        data-tooltip-content="Permet de générer une réponse basée spécifiquement sur le contenu des documents de votre entreprise."
                    >
                        {mode === CHAT_MODE.QUERY ? <MdOutlineRadioButtonChecked className="w-4 h-4 mx-2"/> :
                            <MdOutlineRadioButtonUnchecked className="w-4 h-4 mx-2"/>}
                        <div>Réponses documentées</div>
                    </div>
                    {hasLLMChatPureOption && <div
                        onClick={() => setMode(CHAT_MODE.QUERY_WITHOUT_RAG)}
                        className={twMerge(`rounded-lg p-2 font-bold flex items-center gap-1 cursor-pointer transform duration-300 border ${mode === CHAT_MODE.QUERY_WITHOUT_RAG ? " bg-perso-lightBlue border-perso-lightBlue text-black" : "  bg-transparent border-white text-white"}`)}
                        data-tooltip-id="main-tooltip"
                        data-tooltip-content="Permet de poser des questions générales sans se baser uniquement sur les documents de votre entreprise."
                    >
                        {mode === CHAT_MODE.QUERY_WITHOUT_RAG ? <MdOutlineRadioButtonChecked className="w-4 h-4 mx-2"/> :
                            <MdOutlineRadioButtonUnchecked className="w-4 h-4 mx-2"/>}
                        <div>Assistant conversationnel</div>
                    </div>}
                    <div
                        onClick={() => setMode(CHAT_MODE.SEARCH)}
                        className={twMerge(`rounded-lg text-white p-2 font-bold flex items-center gap-1 cursor-pointer transform duration-300 border ${mode === CHAT_MODE.SEARCH ? " bg-perso-lightBlue border-perso-lightBlue text-black" : "  bg-transparent border-white text-white"}`)}
                        data-tooltip-id="main-tooltip"
                        data-tooltip-content="Permet de retourner tous les documents qui contiennent des références en lien avec la question posée."
                    >
                        {mode === CHAT_MODE.SEARCH ? <MdOutlineRadioButtonChecked className="w-4 h-4 mx-2"/> :
                            <MdOutlineRadioButtonUnchecked className="w-4 h-4 mx-2"/>}
                        <div>Recherche sémantique</div>
                    </div>
                </div>
            </div>

            <div className="space-y-2">
                {mode === CHAT_MODE.QUERY_WITHOUT_RAG && <div className="flex flex-col gap-8">
                    <div>
                        <div className="font-bold flex items-center gap-2 text-lg">Paramètres</div>
                        {/* TODO 'Historique' to be added. For now enable it only when using pure LLM */}
                        <div
                            className="text-sm flex gap-4 items-center w-fit mt-2"
                            data-tooltip-id="main-tooltip"
                            data-tooltip-content="Permet d'inclure les deux derniers messages en contexte."
                        >
                            <SwitchComponent
                                name="is_virteem_admin"
                                checked={withHistory}
                                onChange={toggleHistory}
                            />
                            <div className="font-bold">Conserver l'historique</div>
                        </div>
                    </div>
                    <div className="text-sm flex flex-col gap-4">
                        <p><b>Note</b><br/>Lorsque l'assistant conversationnel est sélectionné, il peut parfois commettre des erreurs. Nous vous recommandons de vérifier les informations importantes.</p>
                        <p><b>Conseil</b><br/>Utilisez d'abord le mode "Réponses documentées" pour trouver la réponse à votre question. Ensuite, utilisez le mode assistant conversationnel pour reformuler, reformatter, ou synthétiser la réponse.</p>
                    </div>
                </div>}
            </div>
        </div>
    )
}
