import { useEffect, useState } from 'react'
import api from '../../utils/api'
import { useAppContext } from '../../context/Context'
import { ACTION, GENERIC_MODAL_ACTIONS } from '../../context/actionTypes'
import useCompanies from '../companies/useCompanies'
import { setBottomBarError } from '../../utils/Functions'

export default function useUsers() {
    const [_, dispatch] = useAppContext()
    const [users, setUsers] = useState([])
    const [{ companies }, { loadCompanies }] = useCompanies()

    useEffect(() => {
        loadUsers()
    }, [])

    const loadUsers = async () => {
        try {
            const responseUsers = await api.get('/user/all')
            setUsers(responseUsers.data)
            loadCompanies()
        }
        catch (error) {
            setBottomBarError(dispatch, error)
        }
    }

    const createUser = () => {
        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                props: { reload: loadUsers },
                action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_ADMIN
            }
        })
    }

    return [{ users, companies }, { loadUsers, createUser }]
}

