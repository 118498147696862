import React from "react";
import "./SuccessMessageNote.css";

interface SuccessMessageNoteProps {
  display: any;
}

const SuccessMessageNote: React.FC<SuccessMessageNoteProps> = ({ display }) => {
     return (
        <div className="success-message" style={{color: '#366F27'}}><p>{display}</p></div>
  );
}
export default SuccessMessageNote;