import React from "react";
import "./SubmitButton.css";
import { twMerge } from 'tailwind-merge';
interface BlueLinkProps {
  text: string;
  className?: string;
  onClick?: () => void;
}

const BlueLink: React.FC<BlueLinkProps> = ({ text, className, onClick }) => {
     return (
         <div className="flex flex-col items-center">
             <button className={twMerge("text-virteem-bluelink hover:text-virteem-bgBlue underline", className)} type="submit" onClick={onClick}>
                 {text}
             </button>
         </div>


     );
}
export default BlueLink;
