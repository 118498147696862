import GenericModal from '../../../utils/GenericModal'
import { ACTION } from '../../../context/actionTypes'
import { DropDownMenuComponent, InputComponent, SwitchComponent } from '../../../utils/FormComponents'
import useCreateOrUpdateAdminModal from '../../../hooks/users/useCreateOrUpdateAdminModal'
import { FiPlusCircle } from "react-icons/fi";
import { GrFormPrevious } from "react-icons/gr";

export default function CreateOrUpdateAdminModal(props) {
    console.log("PROPS", props)
    const [{
        companies,
        company,
        user,
        errors,
        companyErrors
    }, {
        dispatch,
        handleSubmit,
        handleInputChange,
        initCompany,
        emptyCompany,
        handleInputChangeCompany
    }] = useCreateOrUpdateAdminModal(props)

    const {
        first_name,
        last_name,
        email,
        status,
        company_id,
        is_admin
    } = user

    return (
        <GenericModal
            className="w-3/4 lg:w-2/5 xl:w-1/2 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={false}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Créer / Modifier Un Utilisateur</div>
            <form onSubmit={handleSubmit} noValidate className=''>
                <div className='p-8'>
                    <div className='grid grid-cols-2 gap-4'>
                        <InputComponent
                            className="col-span-1"
                            label="Prénom :"
                            name="first_name"
                            value={first_name}
                            onChange={handleInputChange}
                            error={errors.first_name}
                        />
                        <InputComponent
                            className="col-span-1"
                            label="Nom :"
                            name="last_name"
                            value={last_name}
                            onChange={handleInputChange}
                            error={errors.last_name}
                        />
                        <InputComponent
                            className="col-span-1"
                            label="Email :"
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                            error={errors.email}
                        />
                        <>
                            {company ? (
                                <>
                                    <div>
                                        <InputComponent
                                            className="col-span-1"
                                            label="Nom de l'entreprise :"
                                            name="name"
                                            value={company.name}
                                            onChange={handleInputChangeCompany}
                                            error={companyErrors.name}
                                        />
                                        <div onClick={emptyCompany} className='flex hover:underline items-center text-sm text-gray-600 hover:text-gray-700 cursor-pointer'>
                                            <GrFormPrevious className="w-4 h-4" />
                                            <div>Utiliser une entreprise existante</div>
                                        </div>
                                    </div>
                                    <InputComponent
                                        className="col-span-1"
                                        label="Email de l'entreprise :"
                                        name="email"
                                        value={company.email}
                                        onChange={handleInputChangeCompany}
                                        error={companyErrors.email}
                                    />
                                    <InputComponent
                                        className="col-span-1"
                                        label="Secteur de l'entreprise :"
                                        name="sector"
                                        value={company.sector}
                                        onChange={handleInputChangeCompany}
                                        error={companyErrors.sector}
                                    />
                                </>
                            ) : (
                                <div>
                                    <DropDownMenuComponent
                                        label="Entreprise :"
                                        name="company_id"
                                        items={companies}
                                        labelField="name"
                                        onItemSelected={handleInputChange}
                                        currentItem={companies.find((company) => company.id === company_id)}
                                        className='w-full'
                                        error={errors.company_id}
                                    />
                                    {!user.id && (
                                        <div onClick={initCompany} className='flex hover:underline gap-1 items-center text-sm text-gray-600 hover:text-gray-700 cursor-pointer'>
                                            <FiPlusCircle className="w-4 h-4" />
                                            <div>Ajouter une nouvelle entreprise</div>
                                        </div>
                                    )}
                                </div>
                            )}

                        </>
                        <SwitchComponent
                            label="Administrateur :"
                            name="is_admin"
                            checked={is_admin}
                            onChange={handleInputChange}
                            checkedLabel="Oui"
                            uncheckedLabel="Non"
                            className='col-span-2 xl:col-span-1'
                        />
                        <SwitchComponent
                            label="Etat du compte :"
                            name="status"
                            checked={status}
                            onChange={handleInputChange}
                            checkedLabel="Activé"
                            uncheckedLabel="Désactivé"
                            className='col-span-2 xl:col-span-1'
                        />

                    </div>
                </div>

                <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                    <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className=' rounded border bg-white text-black text-sm font-light py-1 px-2'>
                        Annuler
                    </button>
                    <button type='submit' className=' rounded bg-gray-600 hover:bg-gray-700 hover:shadow text-white text-sm py-1 px-2 transform duration-300'>
                        Valider
                    </button>
                </div>
            </form>
        </GenericModal>
    )
}
