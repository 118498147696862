import {useState} from 'react';
import {useAppContext} from "../../../../context/Context";
import api from "../../../../utils/api";
import {ACTION} from "../../../../context/actionTypes";
import {setBottomBarError} from "../../../../utils/Functions";
import {ApiKeySchema} from "./ApiKeys";

interface APIKeyCreationProps {
    name?: string;
    id?: number;
    token?: string;
    regenerate?: boolean;
}

export type AddOrUpdateAPIKeyCallback = (key: ApiKeySchema) => void;

export interface UseCreateApiKeyProps {
    addOrUpdateApiKey: AddOrUpdateAPIKeyCallback;
    apiKey?: APIKeyCreationProps
}

export default function useCreateOrUpdateApiKey({addOrUpdateApiKey, apiKey: editApiKey}: UseCreateApiKeyProps) {
    const [apiKey, setApiKey] = useState<APIKeyCreationProps>(editApiKey ? editApiKey : {});
    const [errors, setErrors] = useState<APIKeyCreationProps & { submit?: string }>({});
    const [{user}, dispatch] = useAppContext();

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setApiKey(prev => ({...prev, [name]: value}));
    };

    const validateForm = () => {
        let curErrors: APIKeyCreationProps = {};
        // TODO english version
        // if (!option.name) curErrors = { ...curErrors, name: "API key name is required" };
        if (!apiKey.name) curErrors.name = "Le nom de la clé d'API est requis";
        return curErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const curErrors = validateForm();
        setErrors(curErrors);
        if (Object.keys(curErrors).length > 0) return;

        try {
            const response = await (
                apiKey.id ?
                    api.patch(`/company/${user.company_id}/api-keys/${apiKey.id}`, {edited_api_key: apiKey})
                    : api.post(`/company/${user.company_id}/api-keys`, {new_api_key: apiKey})
            );
            dispatch({type: ACTION.SET_GENERIC_MODAL, payload: null});
            if (!response.data.token && apiKey.token) {
                response.data.token = apiKey.token;
            }
            addOrUpdateApiKey(response.data);
        } catch (error) {
            console.error("Failed to create api key", error);
            // TODO english version
            //setErrors({ submit: "Failed to submit API key data." });
            setErrors({submit: "Échec de soumission des données de clé d'API."});
            setBottomBarError(dispatch, error);
        }
    };

    const toggleMe = () => dispatch({type: ACTION.SET_GENERIC_MODAL, payload: null});

    return [{
        apiKey,
        errors
    }, {
        toggleMe,
        handleInputChange,
        handleSubmit
    }];
}
