import { GENERIC_MODAL_ACTIONS } from "../../../context/actionTypes";
import usePlans from "../../../hooks/plans/usePlans"
import { FaEdit } from "react-icons/fa";
import { FiTrash } from 'react-icons/fi';
import ListDisplayer from "../../../utils/ListDisplayer";
import { AiOutlinePlusCircle } from "react-icons/ai";

export default function Plan() {
    const [{ plans }, { loadPlans, createPlan }] = usePlans()

    const columnsOnSummary = [
        {
            label: 'Nom',
            key: 'name',
            className: 'font-semibold',
            colSpan: 2,
        },
        {
            label: 'Description',
            key: 'description',
            colSpan: 3,
        },
        {
            label: 'Prix',
            key: 'price',
            colSpan: 1,
        },
        {
            label: 'Quotas',
            key: 'quota_usage',
            colSpan: 1,
        },
        {
            label: 'Documents',
            key: 'document_limit',
            colSpan: 1,
        },
        {
            label: 'Créé le',
            key: 'created_at',
            type: 'date',
            colSpan: 1,
        },
    ]

    const actionsMenu = [
        {
            action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_PLAN,
            label: 'Modifier',
            enabled: (e) => true,
            Icon: FaEdit,
        },
        {
            action: GENERIC_MODAL_ACTIONS.DELETE_PLAN,
            label: 'Supprimer',
            enabled: (e) => true,
            Icon: FiTrash,
        }
    ];

    return (
        <div className='pt-4 px-4 text-left flex flex-col max-h-screen overflow-hidden text-base'>
            <div className='flex'>
                <button
                    className="btn-transition bg-gray-600 hover:bg-opacity-75 mb-4"
                    onClick={() => createPlan()}
                >
                    <AiOutlinePlusCircle className="h-5 w-5" />
                    <div>Créer un plan</div>
                </button>
            </div>
            <ListDisplayer
                 columnsOnSummary={columnsOnSummary}
                 items={plans}
                 actionsMenu={actionsMenu}
                 reload={loadPlans}
                 defaultSortKey="created_at"
             />
        </div>
    )
}
