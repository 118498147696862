import React, {useState} from 'react';
import SubmitButton from "./SubmitButton";
import {useAppContext} from "../../../../context/Context";
import './progressBar.css';
import './ChangePassword.css';
import api from "../../../../utils/api";
import axios from "axios";
import ErrorMessageNote from "../messageNote/ErrorMessageNote";
import SuccessMessageNote from "../messageNote/SuccessMessageNote";
import ProgressBarMessageNote from "../messageNote/ProgressBarMessageNote";
import PwdCriteriaChecker from "./PwdCriteriaChecker";
import TitleAndSubTitle from "../TitleAndSubTitle";
import ToggleCheckbox from "../ToggleCheckBox";



/** Main component for changing the password
 *
 * @constructor
 */
const ChangePassword: React.FC = () => {
    // State declarations for email, passwords and error messages
    const [{ user }] = useAppContext()
    const [error, setError] = useState(''); // Error message
    const [success, setSuccess] = useState(''); // Success message
    const [showPassword, setShowPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState(''); // Current password
    const [newPassword, setNewPassword] = useState(''); // New password
    const [confirmPassword, setConfirmPassword] = useState(''); // Confirm new password



    /** Submission password management
     * Prevents form reloading by default.
     * Checks if new passwords match.
     * Checks the strength of the new password.
     * If the conditions are met, an alert indicates that the password has been changed.
     *
     * Le mot de passe doit contenir au moins 8 caractères, dont au moins une majuscule, une minuscule, un chiffre et un caractère spécial.
     * Les caractères spéciaux suivants sont autorisés: !@#$%^&*_-+=~:;,.?
     *
     * @param event
     */
    const handleChangePassword = async (event: React.FormEvent) => {
        event.preventDefault();
        setError('');
        setSuccess('');


        if (newPassword !== confirmPassword) {
            setError('Les nouveaux mots de passe ne correspondent pas.');
            return;
        }

        const options = {
             headers: {
                    'Content-Type': 'application/json'
                }
        };
        const body = {
            email : user?.email,
            current_password: currentPassword,
            password_update: {
                password: newPassword,
                confirm_password: confirmPassword
            }
        };

        try {
            const response = await api.post('/user/password_update', body,
            options);
            console.debug(response.data);
            if(response.status === 200){
                setSuccess('Le mot de passe a été modifié avec succès. Nous vous incitons à vous reconnecter sur la plateforme.');
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            }
            else{
                setError('Erreur lors de la modification du mot de passe.');
            }

        } catch (error) {
             if (axios.isAxiosError(error)) {
                  console.debug(handleChangePassword.name, error.response);
                 setError(error.response?.data?.detail || 'Erreur lors de la modification du mot de passe final.');
             }
             else {
                 console.debug(handleChangePassword.name, error);
                 setError('Erreur lors de la modification du mot de passe final.');
             }
            console.debug(handleChangePassword.name, Object.keys(error))

        }
    };


    /** Updates the status of the new password and the password strength each time the input field is changed.
   *
   * @param event
   */
    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value;
        setNewPassword(password);
    };

    /** Updates the status of the current password each time the input field is changed.
   *
   * @param event
   */
    const handleCurrentPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value;
        setCurrentPassword(password);
    };

    return (
        /** Return form :
         * Contains fields for email, current password, new password and new password confirmation.
         * Displays password strength in real time.
         * Displays errors if present.
         * Contains a submit button to change password.
         *
         */
        <main className="change-pwd">
            <TitleAndSubTitle h1="Gestion du mot de passe" h2="Mettre à jour votre mot de passe"/>

            <hr/>
            <form onSubmit={handleChangePassword} autoComplete="off">
                <div>
                    <label htmlFor="email">Email</label>
                    <br/>
                    <input className="input-text opacity-35 pointer-events-none"
                           type="email"
                           id="email"
                           value={user?.email}
                           autoComplete="off"
                           required // Field is required
                           readOnly

                    />
                    <i className="bi bi-eye-slash" id="togglePassword"></i>
                </div>
                <div>
                    <label htmlFor="currentPassword">Entrez le mot de passe courant</label>
                    <br/>
                    <input className="input-text"
                           type={
                               showPassword ? "text" : "password"
                           }
                           // autoComplete="off"
                           autoComplete="new-password"
                           id="currentPassword"
                           value={currentPassword}
                             // onChange={(e) => setCurrentPassword(e.target.value)}
                           onChange={handleCurrentPassword}
                           placeholder=""
                           required
                    />
                </div>
                <div>
                    <label htmlFor="newPassword">Entrez le nouveau mot de passe</label>
                    <br/>
                    <input
                        className="input-text"
                        id="newPassword"
                        type={
                            showPassword ? "text" : "password"
                        }
                        value={newPassword}
                        placeholder=""
                        autoComplete="off"
                        onChange={handleNewPasswordChange}
                        required // Field is required
                    />
                    {newPassword.length >= 1 && <ProgressBarMessageNote password={newPassword}/>}
                    <PwdCriteriaChecker password={newPassword}/>
                </div>
                <div>
                    <label htmlFor="confirmPassword">Confirmer votre mot de passe </label>
                    <br/>
                    <input className="input-text"
                           type={showPassword ? "text" : "password"}
                           id="confirmPassword"
                           required // Field is required
                           value={confirmPassword}
                           autoComplete="off"
                           placeholder=""
                           onChange={(e) =>
                               setConfirmPassword(e.target.value)
                           }
                    />
                </div>
                <br/>
                 <ToggleCheckbox
                     id="check"
                     // label="Afficher le mot de passe"
                     checked={showPassword}
                     onChange={() => setShowPassword(prev => !prev)}
                 />
                {error && <ErrorMessageNote display={error}/>}
                {success && <SuccessMessageNote display={success}/>}
                <SubmitButton text="Valider"/>
            </form>

        </main>
    )
        ;
}
export default ChangePassword;