import GenericModal from '../../../utils/GenericModal'
import useManageCompanyOptions from '../../../hooks/companies/useManageCompanyOptions';
import { ACTION } from '../../../context/actionTypes';

export default function ManageCompanyOptions(props) {
    const [{ optionsStatus }, { dispatch, toggleOptionStatus, handleSubmit }] = useManageCompanyOptions(props);

    return (
        <GenericModal
            className="w-3/4 lg:w-2/5 xl:w-1/2 2xl:w-1/4 overflow-hidden text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={false}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm'>Modifier l'état des options</div>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className=' overflow-y-auto max-h-96'>


                    {optionsStatus.map((option, key) => (
                        <div key={key} className='flex justify-between items-center p-4 border-b'>
                            <div className='w-full'>
                                <div className='flex gap-2 w-full' >
                                    <div className='font-semibold flex-grow'>{option.name}</div>
                                    <div className='font-semibold w-24 flex-shrink-0 '>{option.price}<span className='text-xs font-light'> €/mois</span></div>
                                </div>
                                <div className='text-sm text-gray-600'>{option.description}</div>
                            </div>
                            <div>
                                <label htmlFor={`toggle_${key}`} className="inline-flex relative items-center cursor-pointer">
                                    <input type="checkbox" value="" id={`toggle_${key}`} className="sr-only peer" checked={optionsStatus[key].subscribed} onChange={() => toggleOptionStatus(option.id)} />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 focus:outline-none"></div>
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex justify-end gap-4 p-4'>
                    <button onClick={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })} className='rounded border bg-white text-black text-sm font-light py-2 px-4'>
                        Annuler
                    </button>
                    <button onClick={handleSubmit} type='button' className='rounded bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium py-2 px-4'>
                        Valider
                    </button>
                </div>
            </form>
        </GenericModal>
    )
}
