import {Dispatch, useEffect, useState} from "react";
import api from "../../utils/api";
import {setBottomBarError} from "../../utils/Functions";
import {Action, useAppContext} from "../../context/Context";

export enum FunctionOption {
    PRESERVED_REQUESTS_FUNC = 0, // Disables the preservation of sensitive data from queries.
    LLM_CHAT_PURE = 2, // Allow the company to use the LLM without the RAG.
}

export async function fetchHasFunction(dispatch: Dispatch<Action>, functionId: FunctionOption, companyUUID: string): Promise<boolean | undefined> {
    try {
        const response = await api.get(`/company/${companyUUID}/options/has-function/${functionId}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to get if company has FunctionOption ${functionId}`, error);
        setBottomBarError(dispatch, error);
        return undefined;
    }
}

export default function useCheckFunctionOption(functionId: FunctionOption, companyUUID?: string) {
    const [{user}, dispatch] = useAppContext();

    // Initialised at undefined when the request isn't completed yet. It gets set to true of false depending on if the company has the function or not.
    const [hasOption, setHasOption] = useState<boolean | undefined>();

    if (!companyUUID) {
        if (!user) {
            throw new Error("Cannot fetch if a company has a function if no companyUUID is provided and the user is not logged in.");
        }
        companyUUID = user.company.uuid;
    }

    useEffect(() => {
        fetchHasFunction(dispatch, functionId,companyUUID).then(setHasOption).catch(console.error);
    }, [companyUUID, dispatch, functionId]);

    return hasOption;
}