import {useEffect, useState} from 'react';
import {useAppContext} from '../../../../context/Context';
import api from '../../../../utils/api';
import {
    setBottomBarError,
    setBottomBarSucces,
} from '../../../../utils/Functions';
import {ACTION} from '../../../../context/actionTypes';

export default function useIntroductionMessage() {
    const [, dispatch] = useAppContext();

    const [firstText, setfirstText] = useState('');
    const defaultIntroductoryText =
        "Bonjour et bienvenue sur Virteem Companion ! Je suis un assistant conçu pour vous aider à trouver des informations précises dans notre base de connaissances. Posez-moi des questions spécifiques sur notre domaine d'expertise, et je ferai de mon mieux pour vous fournir une réponse pertinente basée sur les documents disponibles. Si vous avez des questions générales ou souhaitez simplement discuter, je risque de ne pas être très bavard. Essayez de poser une question précise pour obtenir des résultats optimaux. Merci !";

    useEffect(() => {
        const getIntroductoryMessage = async () => {
            try {
                const response = await api.get(`/user/text`);
                if (response.data === '') response.data = defaultIntroductoryText;
                setfirstText(response.data);
            } catch (error) {
                console.error('Failed to load introductory text', error);
                setBottomBarError(dispatch, error);
                setfirstText(defaultIntroductoryText);
            }
        };

        getIntroductoryMessage();
    }, [dispatch]);

    const handleSubmit = async (user) => {
        try {
            // Send introductoryText to backend
            await api.patch(`/company/${user.company_id}/text`, {text: firstText});
            dispatch({type: ACTION.SET_GENERIC_MODAL, payload: null});
            setBottomBarSucces(
                dispatch,
                "Votre message d'introduction a été mis à jour !"
            );
        } catch (error) {
            setBottomBarError(dispatch, error);
        }
    };
    return [
        {
            firstText,
            defaultIntroductoryText
        },
        {setfirstText, handleSubmit},
    ];
}
