import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: true,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  elements: {
    line: {
      tension: 0.3,
    },
    point: {
      radius: 0,
    },
  },
};

export const CustomLineChart = ({ dataPoints }) => {
  // Génère les labels au format "jj/mm"
  const labels = dataPoints.map(data => {
    const date = new Date(data.date);
    return date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit' });
  });

  const data = dataPoints.map(data => data.queries);

  const chartData = {
    labels,
    datasets: [
      {
        data,
        borderColor: 'rgba(132,167,220,255)',
        backgroundColor: 'rgba(0, 123, 255, 0.1)',
        fill: true,
        maxBarThickness: 20,
        pointStyle: 'circle',
        pointRadius: 3,
        pointHoverRadius: 6
      }
    ],
  };

  const ChartComponent = dataPoints.length === 1 ? Bar : Line;

  return (
    <ChartComponent options={options} data={chartData} />
  );
};
